// import './Team.css';
import React from 'react';
import HeadWhite from './HeadWhite';
import Banner from './Banner';
import Paper from './Paper';
import {Link} from "react-router-dom";
import Spacer from 'react-add-space';



function Research() {
    return (
	<div>
	    <HeadWhite />
	    <Banner title="PUBLICATIONS"/>
	    <div className="container justify-content-md-center research-container">
	      <div className="row">
		<div className="col-md-3 col-12" style={{paddingBottom:"50px"}}>
		  <div className="row">
                    <div className="col-md-12 col-12">
   		      <Link to="/topics" className="btn btn-outline-primary rounded-pill"><Spacer amount={3}/>Selected topics<Spacer amount={3}/></Link><br/><br/>
		    </div>
		    <div className="col-md-12 col-12">
   		      <Link to="/Research" className="btn btn-outline-primary rounded-pill active"><Spacer amount={5}/>Publications<Spacer amount={5}/></Link><br/><br/>
		    </div>
		    <div className="col-md-12 col-12">
		      <Link to="/Models" className="btn btn-outline-primary rounded-pill"><Spacer amount={8}/>Models<Spacer amount={8}/></Link><br/><br/>
		    </div>
		    <div className="col-md-12 col-12">
   		      <Link to="/Datasets" className="btn btn-outline-primary rounded-pill"><Spacer amount={7}/>Datasets<Spacer amount={7}/></Link><br/><br/>
		    </div>
		  </div>
		</div>

		<div className="col-md-9 col-12 research-content">
		    <nav>
			<div className="nav nav-tabs" id="nav-tab" role="tablist">
			    <a className="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Conference papers</a>
			    <a className="nav-link" id="nav-journal-tab" data-toggle="tab" href="#nav-journal" role="tab" aria-controls="nav-journal" aria-selected="false">Journal Papers</a>
			</div>
		    </nav>
		    <div className="tab-content" id="nav-tabContent">
			<div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
			  <br/><br/>

			  	<h3>2024</h3>
				  <Paper title="Improving Non-Transferable Representation Learning by Harnessing Content and Style." authors="Z. Hong, Z. Wang, L. Shen, Y. Yao, Z. Huang, S. Chen, C. Yang, M. Gong, and T. Liu." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight="Spotlight"/>
				  <Paper title="Beyond Linear Spherical Interpolation: Noise Correction for Image Interpolation with Diffusion Models." authors="P. Zheng, Y. Zhang, Z. Fang, T. Liu, D. Lian, and B. Han." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight="Spotlight"/>
				  <Paper title="Negative Label Guided OOD Detection with Pretrained Vision-Language Models." authors="X. Jiang, F. Liu, Z. Fang, H. Chen, T. Liu, F. Zheng, B. Han." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight="Spotlight"/>
				  <Paper title="Early Stopping Against Label Noise Without Validation Data." authors="S. Yuan, L. Feng, and T. Liu." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="Causal Structure Recovery with Latent Variables under Milder Distributional and Graphical Assumptions." authors="X. Li, K. Zhang, and T. Liu." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="Enhancing Contrastive Learning for Ordinal Regression via Ordinal Content Preserved Data Augmentation." authors="J. Zheng, Y. Yao, B. Han, D. Wang, and T. Liu." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="IDEAL: Influence-Driven Selective Annotations Empower In-Context Learners in Large Language Models." authors="S. Zhang, X. Xia, Z. Wang, L. Chen, J. Liu, Q. Wu, and T. Liu." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="On the Over-Memorization During Natural, Robust and Catastrophic Overfitting." authors="R. Lin, C. Yu, B. Han, and T. Liu." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="Neural Auto-designer for Enhanced Quantum Kernels." authors="C. Lei, Y. Du, P. Mi, J. Yu, and T. Liu." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="Enhancing One-Shot Federated Learning Through Data and Ensemble Co-Boosting." authors="R. Dai, Y. Zhang, A. Li, T. Liu, X. Yang, B. Han." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="Out-of-Distribution Detection with Negative Prompts." authors="J. Nie, Y. Zhang, Z. Fang, T. Liu, B. Han, X. Tian." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="Federated Causal Discovery from Heterogeneous Data." authors="L. Li, I. Ng, G. Luo, B. Huang, G. Chen, T. Liu, B. Gu, and K. Zhang." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="FedImpro: Measuring and Improving Client Update in Federated Learning." authors="Z. Tang, Y. Zhang, S. Shi, X. Tian, T. Liu, B. Han, and X. Chu." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				  <Paper title="Negative Label Guided OOD Detection with Pretrained Vision-Language Models." authors="X. Jiang, F. Liu, Z. Fang, H. Chen, T. Liu, F. Zheng, and B. Han." pdf="" conference="In ICLR, 2024." source="" code="" project="" highlight=""/>
				<br/><hr/><br/>

				<h3>2023</h3>
				  <Paper title="Eliminating Catastrophic Overfitting Via Abnormal Adversarial Examples Regularization." authors="R. Lin, C. Yu, and T. Liu." pdf="https://openreview.net/pdf?id=Oj7Mrb4009" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="InstanT: Semi-supervised Learning with Instance-dependent Thresholds." authors="M. Li, R. Wu, H. Liu, J. Yu, X. Yang, B. Han, and T. Liu." pdf="https://openreview.net/pdf?id=txv7TnPvOi" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="CS-Isolate: Extracting Hard Confident Examples by Content and Style Isolation." authors="Y. Lin, Y. Yao, X. Shi, M. Gong, X. Shen, D. Xu, and T. Liu." pdf="https://openreview.net/pdf?id=Lkc0KjsDFv" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Subclass-Dominant Label Noise: A Counterexample for the Success of Early Stopping." authors="Y. Bai, Z. Han, E. Yang, J. Yu, B. Han, D. Wang, and T. Liu." pdf="https://openreview.net/pdf?id=kR21XsZeAr" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="FlatMatch: Bridging Labeled Data and Unlabeled Data with Cross-Sharpness for Semi-Supervised Learning." authors="Z. Huang, L. Shen, J. Yu, B. Han, and T. Liu." pdf="https://openreview.net/pdf?id=pE3yaP0Eqg" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Towards Label-free Scene Understanding by Vision Foundation Models." authors="R. Chen, Y. Liu, L. Kong, N. Chen, X. Zhu, Y. Ma, T. Liu, and W. Wang." pdf="https://openreview.net/pdf?id=C8JdyM7B8I" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Out-of-distribution Detection Learning with Unreliable Out-of-distribution Sources." authors="H. Zheng, Q. Wang, Z. Fang, X. Xia, F. Liu, T. Liu, and B. Han." pdf="https://openreview.net/pdf?id=87Qnneer8l" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Diversified Outlier Exposure for Out-of-Distribution Detection via Informative Extrapolation." authors="J. Zhu, G. Yu, J. Yao, T. Liu, G. Niu, M. Sugiyama, and B. Han." pdf="https://openreview.net/pdf?id=RuxBLfiEqI" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="FedFed: Feature Distillation against Data Heterogeneity in Federated Learning." authors="Z. Yang, Y. Zhang, Y. Zheng, X. Tian, H. Peng, T. Liu, and B. Han." pdf="https://openreview.net/pdf?id=phnGilhPH8" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Defending against Data-Free Model Extraction by Distributionally Robust Defensive Training." authors="Z. Wang, L. Shen, T. Liu, T. Duan, Y. Zhu, D. Zhan, D. Doermann, and M. Gao." pdf="https://openreview.net/pdf?id=7DZAVpOoAK" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="An Efficient Dataset Condensation Plugin and Its Application to Continual Learning." authors="E. Yang, L. Shen, Z. Wang, T. Liu, and G. Guo." pdf="https://openreview.net/pdf?id=Murj6wcjRw" conference="In NeurIPS, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Holistic Label Correction for Noisy Multi-Label Classification." authors="X. Xia, J. Deng, W. Bao, Y. Du, B. Han, S. Shan, and T. Liu." pdf="https://openaccess.thecvf.com/content/ICCV2023/papers/Xia_Holistic_Label_Correction_for_Noisy_Multi-Label_Classification_ICCV_2023_paper.pdf" conference="In ICCV, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Combating Noisy Labels with Sample Selection by Mining High-Discrepancy Examples." authors="X. Xia, B. Han, Y, Zhan, J. Yu, M. Gong, C. Gong, and T. Liu." pdf="https://openaccess.thecvf.com/content/ICCV2023/papers/Xia_Combating_Noisy_Labels_with_Sample_Selection_by_Mining_High-Discrepancy_Examples_ICCV_2023_paper.pdf" conference="In ICCV, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Late Stopping: Avoiding Confidently Learning from Mislabeled Examples." authors="S. Yuan, L. Feng, and T. Liu." pdf="https://openaccess.thecvf.com/content/ICCV2023/papers/Yuan_Late_Stopping_Avoiding_Confidently_Learning_from_Mislabeled_Examples_ICCV_2023_paper.pdf" conference="In ICCV, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="PADDLES: Phase-Amplitude Spectrum Disentangled Early Stopping for Learning with Noisy Labels." authors="H. Huang, H. Kang, S. Liu, O. Salvado, T. Rakotoarivelo, D. Wang, and T. Liu." pdf="https://openaccess.thecvf.com/content/ICCV2023/papers/Huang_PADDLES_Phase-Amplitude_Spectrum_Disentangled_Early_Stopping_for_Learning_with_Noisy_ICCV_2023_paper.pdf" conference="In ICCV, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="HumanMAC: Masked Motion Completion for Human Motion Prediction." authors="L. Chen, J. Zhang, Y. Li, Y. Pang, X. Xia, and T. Liu." pdf="https://openaccess.thecvf.com/content/ICCV2023/papers/Chen_HumanMAC_Masked_Motion_Completion_for_Human_Motion_Prediction_ICCV_2023_paper.pdf" conference="In ICCV, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Point-Query Quadtree for Crowd Counting, Localization, and More." authors="C. Liu, H. Lu, Z. Cao, and T. Liu." pdf="https://arxiv.org/pdf/2308.13814.pdf" conference="In ICCV, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="ALIP: Adaptive Language-Image Pre-training with Synthetic Caption." authors="K. Yang, J. Deng, X. An, J. Li, Z. Feng, J. Guo, J. Yang, and T. Liu." pdf="https://openaccess.thecvf.com/content/ICCV2023/papers/Yang_ALIP_Adaptive_Language-Image_Pre-Training_with_Synthetic_Caption_ICCV_2023_paper.pdf" conference="In ICCV, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Multiscale Representation for Real-Time Anti-Aliasing Neural Rendering." authors="D. Hu, Z. Zhang, T. Hou, T. Liu, H. Fu, and M. Gong." pdf="https://openaccess.thecvf.com/content/ICCV2023/papers/Hu_Multiscale_Representation_for_Real-Time_Anti-Aliasing_Neural_Rendering_ICCV_2023_paper.pdf" conference="In ICCV, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Phase-aware Adversarial Defense for Improving Adversarial Robustness." authors="D. Zhou, N. Wang, H. Yang, X. Gao, and T. Liu." pdf="https://proceedings.mlr.press/v202/zhou23m/zhou23m.pdf" conference="In ICML, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Eliminating Adversarial Noise via Information Discard and Robust Representation Restoration." authors="D. Zhou, Y. Chen, N. Wang, D. Liu, X. Gao, and T. Liu." pdf="https://proceedings.mlr.press/v202/zhou23b/zhou23b.pdf" conference="In ICML, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Which is Better for Learning with Noisy Labels: The Semi-supervised Method or Modeling Label Noise?" authors="Y. Yao, M. Gong, Y. Du, J. Yu, B. Han, K. Zhang, and T. Liu." pdf="https://proceedings.mlr.press/v202/yao23a/yao23a.pdf" conference="In ICML, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Exploring Model Dynamics for Accumulative Poisoning Discovery." authors="J. Zhu, X. Guo, J. Yao, C. Du, L. He, S. Yuan, T. Liu, L. Wang, B. Han." pdf="https://proceedings.mlr.press/v202/zhu23d/zhu23d.pdf" conference="In ICML, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Unleashing Mask: Explore the Intrinsic Out-of-Distribution Detection Capability." authors="J. Zhu, H. Li, J. Yao, T. Liu, J. Xu, and B. Han." pdf="https://arxiv.org/pdf/2306.03715.pdf" conference="In ICML, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Detecting Out-of-distribution Data through In-distribution Class Prior." authors="X. Jiang, F. Liu, Z. Fang, H. Chen, T. Liu, F. Zheng, and B. Han." pdf="https://proceedings.mlr.press/v202/jiang23e/jiang23e.pdf" conference="In ICML, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Evolving Semantic Prototype Improves Generative Zero-Shot Learning." authors="S. Chen, W. Hou, Z. Hong, X. Ding, Y. Song, X. You, T. Liu, and K. Zhang." pdf="https://arxiv.org/pdf/2306.06931.pdf" conference="In ICML, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Diversity-enhancing Generative Network for Few-shot Hypothesis Adaptation." authors="R. Dong, F. Liu, H. Chi, T. Liu, M. Gong, G. Niu, M. Sugiyama, B. Han." pdf="https://proceedings.mlr.press/v202/dong23d/dong23d.pdf" conference="In ICML, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="A Universal Unbiased Method for Classification from Aggregate Observations." authors="Z. Wei, L. Feng, B. Han, T. Liu, G. Niu, X. Zhu, and H.T. Shen." pdf="https://proceedings.mlr.press/v202/wei23a/wei23a.pdf" conference="In ICML, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Robust Generalization against Corruptions via Worst-Case Sharpness Minimization." authors="Z. Huang, M. Zhu, X. Xia, L. Shen, Y. Yu, C. Gong, B. Han, B. Du, and T. Liu." pdf="https://openaccess.thecvf.com/content/CVPR2023/papers/Huang_Robust_Generalization_Against_Photon-Limited_Corruptions_via_Worst-Case_Sharpness_Minimization_CVPR_2023_paper.pdf" conference="In CVPR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="BiCro: Noisy Correspondence Rectification for Multi-modality Data via Bi-directional Cross-modal Similarity Consistency." authors="S. Yang, X. Pan, K. Wang, Y. You, H. Yao, T. Liu, and M. Xu." pdf="https://openaccess.thecvf.com/content/CVPR2023/papers/Yang_BiCro_Noisy_Correspondence_Rectification_for_Multi-Modality_Data_via_Bi-Directional_Cross-Modal_CVPR_2023_paper.pdf" conference="In CVPR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Architecture, Dataset and Model-Scale Agnostic Data-free Meta-Learning." authors="Z. Hu, L. Shen, Z. Wang, T. Liu, C. Yuan, and D. Tao." pdf="https://openaccess.thecvf.com/content/CVPR2023/papers/Hu_Architecture_Dataset_and_Model-Scale_Agnostic_Data-Free_Meta-Learning_CVPR_2023_paper.pdf" conference="In CVPR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="DeepSolo: Let Transformer Decoder with Explicit Points Solo for Text Spotting." authors="M. Ye, J. Zhang, S. Zhao, J. Liu, T. Liu, B. Du, and D. Tao." pdf="https://openaccess.thecvf.com/content/CVPR2023/papers/Ye_DeepSolo_Let_Transformer_Decoder_With_Explicit_Points_Solo_for_Text_CVPR_2023_paper.pdf" conference="In CVPR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Mosaic Representation Learning for Self-supervised Visual Pre-training." authors="Z. Wang, Z. Chen, Y. Li, Y. Guo, J. Yu, M. Gong, and T. Liu." pdf="https://openreview.net/pdf?id=JAezPMehaUu" conference="In ICLR, 2023." source="" code="" project="" highlight="Notable-top-25%"/>
				  <Paper title="Harnessing Out-Of-Distribution Examples via Augmenting Content and Style." authors="Z. Huang, X. Xia, L. Shen, B. Han, M. Gong, C. Gong, and T. Liu." pdf="https://openreview.net/pdf?id=boNyg20-JDm" conference="In ICLR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Unicom: Universal and Compact Representation Learning for Image Retrieval." authors="X. An, J. Deng, K. Yang, J. Li, Z. Feng, J. Guo, J. Yang, and T. Liu." pdf="https://arxiv.org/pdf/2304.05884.pdf" conference="In ICLR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Moderate Coreset: A Universal Method of Data Selection for Real-world Data-efficient Deep Learning." authors="X. Xia, J. Liu, J. Yu, X. Shen, B. Han, and T. Liu." pdf="https://openreview.net/pdf?id=7D5EECbOaf9" conference="In ICLR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="A Holistic View of Noise Transition Matrix in Deep Learning and Beyond." authors="L. Yong, R. Pi, W. Zhang, X. Xia, J. Gao, X. Zhou, T. Liu, and B. Han." pdf="https://openreview.net/pdf?id=aFzaXRImWE" conference="In ICLR, 2023." source="" code="" project="" highlight="Spotlight"/>
				  <Paper title="Out-of-distribution Detection with Implicit Outlier Transformation." authors="Q. Wang, J. Ye, F. Liu, Q. Dai, M. Kalander, T. Liu, J. Hao, and B. Han." pdf="https://openreview.net/pdf?id=hdghx6wbGuD" conference="In ICLR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Contextual Convolutional Networks." authors="S. Liang, X. Shen, T. Liu, and X. Hua." pdf="https://openreview.net/pdf?id=PldynS56bN" conference="In ICLR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Combating Exacerbated Heterogeneity for Robust Decentralized Models." authors="J. Zhu, J. Yao, T. Liu, Q. Yao, J. Xu, and B. Han." pdf="https://openreview.net/pdf?id=eKllxpLOOm" conference="In ICLR, 2023." source="" code="" project="" highlight=""/>
				  <Paper title="Symmetric Pruning in Quantum Neural Networks." authors="X. Wang, J. Liu, T. Liu, Y. Luo, Y. Du, and D. Tao." pdf="https://openreview.net/pdf?id=K96AogLDT2K" conference="In ICLR, 2023." source="" code="" project="" highlight="Notable-top-25%"/>
				<br/><hr/><br/>

			  	<h3>2022</h3>
				  <Paper title="RSA: Reducing Semantic Shift from Aggressive Augmentations for Self-supervised Learning." authors="Y. Bai, E. Yang, Z. Wang, Y. Du, B. Han, C. Deng, D. Wang, and T. Liu." pdf="https://openreview.net/pdf?id=Cgmk9CicWFl" conference="In NeurIPS, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Estimating Noise Transition Matrix with Label Correlations for Noisy Multi-Label Learning." authors="S. Li, X. Xia, H. Zhang, Y. Zhan, S. Ge, and T. Liu." pdf="https://openreview.net/pdf?id=GwXrGy_vc8m" conference="In NeurIPS, 2022." source="" code="" project="" highlight="Spotlight"/>
				  <Paper title="Class-Dependent Label-Noise Learning with Cycle-Consistency Regularization." authors="D. Cheng, Y. Ning, N. Wang, X. Gao, H. Yang, Y. Du, B. Han, and T. Liu." pdf="https://openreview.net/pdf?id=IvnoGKQuXi" conference="In NeurIPS, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Adversarial Training with Complementary Labels: On the Benefit of Gradually Informative Attacks." authors="J. Zhou, J. Zhu, J. Zhang, T. Liu, G. Niu, B. Han, and M. Sugiyama." pdf="https://openreview.net/pdf?id=s7SukMH7ie9" conference="In NeurIPS, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Towards Lightweight Black-Box Attack Against Deep Neural Networks." authors="C. Sun, Y. Zhang, C. Wan, Q. Wang, Y. Li, T. Liu, B. Han, and X. Tian." pdf="https://openreview.net/pdf?id=Gpqqm4p91Ez" conference="In NeurIPS, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Pluralistic Image Completion with Probabilistic Mixture-of-Experts." authors="X. Xia, W. Yang, J. Ren, Y. Li, Y. Zhan, B. Han, and T. Liu." pdf="https://openreview.net/pdf?id=wuunqp9KVw" conference="In NeurIPS, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Counterfactual Fairness with Partially Known Causal Graph." authors="A. Zuo, S. Wei, T. Liu, B. Han, K. Zhang, and M. Gong." pdf="https://arxiv.org/pdf/2205.13972.pdf" conference="In NeurIPS, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Learning Causally Invariant Representations for Out-of-Distribution Generalization on Graphs." authors="Y. Chen, Y. Zhang, Y. Bian, H. Yang, K. Ma, B. Xie, T. Liu, B. Han, and J. Cheng." pdf="https://openreview.net/pdf?id=A6AFK_JwrIW" conference="In NeurIPS, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="MissDAG: Causal Discovery in the Presence of Missing Data with Continuous Additive Noise Models." authors="E. Gao, I. Ng, M. Gong, L. Shen, W. Huang, T. Liu, K. Zhang, and H. Bondell." pdf="https://proceedings.neurips.cc/paper_files/paper/2022/file/206361867abf7eb01746c3943078da3c-Paper-Conference.pdf" conference="In NeurIPS, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Watermarking for Out-of-distribution Detection." authors="Q. Wang, F. Liu, Y. Zhang, J. Zhang, C. Gong, T. Liu, and B. Han." pdf="https://proceedings.neurips.cc/paper_files/paper/2022/file/63fa7efdd3bcf944a4bd6e0ff6a50041-Paper-Conference.pdf" conference="In NeurIPS, 2022." source="" code="" project="" highlight="Spotlight"/>
				  <Paper title="Out-of-Distribution Detection with An Adaptive Likelihood Ratio on Informative Hierarchical VAE." authors="Y. Li, C. Wang, X. Xia, T. Liu, X. Miao, and B. An." pdf="https://proceedings.neurips.cc/paper_files/paper/2022/file/3066f60a91d652f4dc690637ac3a2f8c-Paper-Conference.pdf" conference="In NeurIPS, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Modeling Adversarial Noise for Adversarial Defense." authors="D. Zhou, N. Wang, B. Han, and T. Liu." pdf="https://arxiv.org/abs/2109.09901" conference="In ICML, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Estimating Instance-dependent Bayes-label Transition Matrix using a Deep Neural Network." authors="S. Yang, E. Yang, B. Han, Y. Liu, M. Xu, G. Niu, and T. Liu." pdf="https://proceedings.mlr.press/v162/yang22p/yang22p.pdf" conference="In ICML, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Improving Adversarial Robustness via Mutual Information Estimation." authors="D. Zhou, N. Wang, X. Gao, B. Han, X. Wang, Y. Zhan, and T. Liu." pdf="https://proceedings.mlr.press/v162/zhou22j/zhou22j.pdf" conference="In ICML, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Understanding Robust Overfitting of Adversarial Training and Beyond." authors="C. Yu, B. Han, L. Shen, J. Yu, C. Gong, M. Gong, and T. Liu." pdf="https://arxiv.org/pdf/2206.08675.pdf" conference="In ICML, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="To Smooth or Not? When Label Smoothing Meets Noisy Labels." authors="J. Wei, H. Liu, T. Liu, G. Niu, M. Sugiyama, and Y. Liu." pdf="https://proceedings.mlr.press/v162/wei22b/wei22b.pdf" conference="In ICML, 2022." source="" code="" project="" highlight="Long Talk"/>
				  <Paper title="Sample-Efficient Kernel Mean Estimator with Marginalized Corrupted Data." authors="X. Xia, S. Shan, M. Gong, N. Wang, F. Gao, H. Wei, and T. Liu." pdf="https://dl.acm.org/doi/pdf/10.1145/3534678.3539318?casa_token=HcSCI55jxr8AAAAA:2AitzO65vAML4E3f0BzufJPa2kMU8jCDnrol6E2w_XwrYMTSl6HC7qogqY4B3AkAzZk5GIWEVe0" conference="In KDD, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Bilateral Dependency Optimization: Defending Against Model-inversion Attacks." authors="X. Peng, F. Liu, J. Zhang, L. Lan, J. Ye, T. Liu, and B. Han." pdf="https://arxiv.org/pdf/2206.05483.pdf" conference="In KDD, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Robust Weight Perturbation for Adversarial Training." authors="C. Yu, B. Han, M. Gong, L. Shen, S. Ge, B. Du, and T. Liu." pdf="https://www.ijcai.org/proceedings/2022/0512.pdf" conference="In IJCAI, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Fair Classification with Instance-dependent Label Noise." authors="S. Wu, M. Gong, B. Han, Y. Liu, and T. Liu" pdf="https://openreview.net/forum?id=s-pcpETLpY" conference="In CLeaR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Selective-Supervised Contrastive Learning with Noisy Labels." authors="S. Li, X. Xia, S. Ge, and T. Liu." pdf="https://openaccess.thecvf.com/content/CVPR2022/papers/Li_Selective-Supervised_Contrastive_Learning_With_Noisy_Labels_CVPR_2022_paper.pdf" conference="In CVPR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="CRIS: CLIP-Driven Referring Image Segmentation." authors="Z. Wang, Y. Lu, Q. Li, X. Tao, Y. Guo, M. Gong, and T. Liu." pdf="https://openaccess.thecvf.com/content/CVPR2022/papers/Wang_CRIS_CLIP-Driven_Referring_Image_Segmentation_CVPR_2022_paper.pdf" conference="In CVPR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Killing Two Birds with One Stone: Efficient and Robust Training of Face Recognition CNNs by Partial FC." authors="X. An, J. Deng, J. Guo, Z. Feng, X. Zhu, J. Yang, and T. Liu." pdf="https://openaccess.thecvf.com/content/CVPR2022/papers/An_Killing_Two_Birds_With_One_Stone_Efficient_and_Robust_Training_CVPR_2022_paper.pdf" conference="In CVPR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Exploring Set Similarity for Dense Self-supervised Representation Learning." authors="Z. Wang, Q. Li, G. Zhang, P. Wan, W. Zheng, N. Wang, M. Gong, and T. Liu." pdf="https://openaccess.thecvf.com/content/CVPR2022/papers/Wang_Exploring_Set_Similarity_for_Dense_Self-Supervised_Representation_Learning_CVPR_2022_paper.pdf" conference="In CVPR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Instance-Dependent Label-Noise Learning With Manifold-Regularized Transition Matrix Estimation." authors="D. Cheng, T. Liu, Y. Ning, N. Wang, B. Han, G. Niu, X. Gao, and M. Sugiyama." pdf="https://arxiv.org/pdf/2206.02791.pdf" conference="In CVPR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Mutual Quantization for Cross-Modal Search with Noisy Labels." authors="E. Yang, D. Yao, T. Liu, and C. Deng." pdf="https://openaccess.thecvf.com/content/CVPR2022/papers/Yang_Mutual_Quantization_for_Cross-Modal_Search_With_Noisy_Labels_CVPR_2022_paper.pdf" conference="In CVPR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="SimT: Handling Open-set Noise for Domain Adaptive Semantic Segmentation." authors="X. Guo, J. Liu, T. Liu, and Y. Yuan." pdf="https://arxiv.org/pdf/2203.15202.pdf" conference="In CVPR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Train Me to Fight: An On-Device Personalized and Machine-Learning-Based Malware Detection." authors="A. Pasdar, Y. Lee, T. Liu, and S. Hong" pdf="" conference="In CCGRID, 2022." source="https://ieeexplore.ieee.org/document/9826004" code="" project="" highlight=""/>
				  <Paper title="Rethinking Class-Prior Estimation for Positive-Unlabeled Learning." authors="Y. Yao, T. Liu, B. Han, M. Gong, G. Niu, M. Sugiyama, and D. Tao" pdf="https://openreview.net/forum?id=aYAA-XHKyk&referrer=%5BAuthor%20Console%5D(%2Fgroup%3Fid%3DICLR.cc%2F2022%2FConference%2FAuthors%23your-submissions" conference="In ICLR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Sample Selection with Uncertainty of Losses for Learning with Noisy Labels." authors="X. Xia, T. Liu, B. Han, M. Gong, J. Yu, G. Niu, and M. Sugiyama" pdf="https://openreview.net/forum?id=xENf4QUL4LW&referrer=%5BAuthor%20Console%5D(%2Fgroup%3Fid%3DICLR.cc%2F2022%2FConference%2FAuthors%23your-submissions" conference="In ICLR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Adversarial Robustness Through the Lens of Causality." authors="Y. Zhang, M. Gong, T. Liu, G. Niu, X. Tian, B. Han, B. Schölkopf, and K. Zhang" pdf="https://openreview.net/forum?id=cZAi1yWpiXQ&referrer=%5BAuthor%20Console%5D(%2Fgroup%3Fid%3DICLR.cc%2F2022%2FConference%2FAuthors%23your-submissions" conference="In ICLR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Learning with Noisy Labels Revisited: A Study Using Real-World Human Annotations." authors="J. Wei, Z. Zhu, H. Cheng, T. Liu, G. Niu, and Y. Liu" pdf="https://openreview.net/forum?id=TBWA6PLJZQm&referrer=%5BAuthor%20Console%5D(%2Fgroup%3Fid%3DICLR.cc%2F2022%2FConference%2FAuthors%23your-submissions" conference="In ICLR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Exploiting Class Activation Value for Partial-Label Learning." authors="F. Zhang, L. Feng, B. Han, T. Liu, G. Niu, T. Qin, and M. Sugiyama" pdf="https://openreview.net/forum?id=qqdXHUGec9h&referrer=%5BAuthor%20Console%5D(%2Fgroup%3Fid%3DICLR.cc%2F2022%2FConference%2FAuthors%23your-submissions" conference="In ICLR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Reliable Adversarial Distillation with Unreliable Teachers." authors="J. Zhu, J. Yao, B. Han, J. Zhang, T. Liu, G. Niu, J. Zhou, J. Xu, and H. Yang" pdf="https://openreview.net/forum?id=u6TRGdzhfip&referrer=%5BAuthor%20Console%5D(%2Fgroup%3Fid%3DICLR.cc%2F2022%2FConference%2FAuthors%23your-submissions" conference="In ICLR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Understanding and Improving Graph Injection Attack by Promoting Unnoticeabilitys." authors="Y. Chen, H. Yang, Y. Zhang, M. Kaili, T. Liu, B. Han, and J. Cheng" pdf="https://openreview.net/forum?id=wkMG8cdvh7-&referrer=%5BAuthor%20Console%5D(%2Fgroup%3Fid%3DICLR.cc%2F2022%2FConference%2FAuthors%23your-submissions" conference="In ICLR, 2022." source="" code="" project="" highlight=""/>
				  <Paper title="Meta Discovery: Learning to Discover Novel Classes given Very Limited Data." authors="H. Chi, F. Liu, W. Yang, L. Lan, T. Liu, B. Han, G. Niu, M. Zhou, and M. Sugiyama" pdf="https://openreview.net/forum?id=MEpKGLsY8f&referrer=%5BAuthor%20Console%5D(%2Fgroup%3Fid%3DICLR.cc%2F2022%2FConference%2FAuthors%23your-submissions" conference="In ICLR, 2022." source="" code="" project="" highlight="Spotlight"/>
				<br/><hr/><br/>

                <h3>2021</h3>
				<Paper title="Me-Momentum: Extracting Hard Confident Examples from Noisily Labeled Data." authors="Y. Bai and T. Liu" pdf="https://openaccess.thecvf.com/content/ICCV2021/papers/Bai_Me-Momentum_Extracting_Hard_Confident_Examples_From_Noisily_Labeled_Data_ICCV_2021_paper.pdf" conference="In ICCV, 2021." source="" code="" project="" highlight="Oral"/>
				<Paper title="Removing Adversarial Noise in Class Activation Feature Space." authors="D. Zhou, N. Wang, C. Peng, X. Gao, X. Wang, J. Yu, and T. Liu" pdf="https://openaccess.thecvf.com/content/ICCV2021/papers/Zhou_Removing_Adversarial_Noise_in_Class_Activation_Feature_Space_ICCV_2021_paper.pdf" conference="In ICCV, 2021." source="" code="" project="" highlight=""/>
				<Paper title="Understanding and Improving Early Stopping for Learning with Noisy Labels." authors="Y. Bai, E. Yang, B. Han, Y. Yang, J. Li, Y. Mao, G. Niu, and T. Liu." pdf="https://arxiv.org/abs/2106.15853" conference="In NeurIPS, 2021." source="" code="" project="" highlight=""/>
				<Paper title="Confident-Anchor-Induced Multi-Source-Free Domain Adaptation." authors="J. Dong, Z. Fang, A. Liu, G. Sun, and T. Liu." pdf="" conference="In NeurIPS, 2021." source="https://papers.nips.cc/paper/2021/file/168908dd3227b8358eababa07fcaf091-Paper.pdf" code="" project="" highlight=""/>
				<Paper title="Instance-Dependent Label-Noise Learning under Structural Causal Models." authors="Y. Yao, T. Liu, M. Gong, B. Han, G. Niu, and K. Zhang." pdf="https://arxiv.org/abs/2109.02986" conference="In NeurIPS, 2021." source="" code="" project="" highlight=""/>
				<Paper title="Probabilistic Margins for Instance Reweighting in Adversarial Training." authors="Q. Wang, F. Liu, B. Han, T. Liu, C. Gong, G. Niu, M. Zhou, and M. Sugiyama." pdf="https://arxiv.org/abs/2106.07904" conference="In NeurIPS, 2021." source="" code="" project="" highlight=""/>
				<Paper title="TOHAN: A One-step Approach towards Few-shot Hypothesis Adaptation." authors="H. Chi, F. Liu, W. Yang, L. Lan, T. Liu, B. Han, W. Cheung, and J. Kwok." pdf="https://arxiv.org/abs/2106.06326" conference="In NeurIPS, 2021." source="" code="" project="" highlight="Spotlight"/>
            	<Paper title="A Second-Order Approach to Learning with Instance-Dependent Label Noise." authors="Z. Zhu, T. Liu, and Y. Liu." pdf="https://arxiv.org/pdf/2012.11854.pdf" conference="In CVPR, 2021." highlight="Oral"/>
				<Paper title="Revisiting Knowledge Distillation: An Inheritance and Exploration Framework. " authors="Z. Huang, X. Shen, J. Xing, T. Liu, X. Tian, H. Li, B. Deng, J. Huang, and X. Hua." pdf="https://openaccess.thecvf.com/content/CVPR2021/papers/Huang_Revisiting_Knowledge_Distillation_An_Inheritance_and_Exploration_Framework_CVPR_2021_paper.pdf" conference="In CVPR, 2021." highlight=""/>
				<Paper title="Relational Subsets Knowledge Distillation for Long-tailed Retinal Diseases Recognition." authors="L. Ju, X. Wang, L. Wang, T. Liu, X. Zhao, T. Drummond, D. Mahapatra, Z. Ge" pdf="" conference="In MICCAI, 2021." source="https://arxiv.org/abs/2104.11057" code="" project="" highlight=""/>
                <Paper title="Confidence Scores Make Instance-dependent Label-noise Learning Possible" authors="A. Berthon, B. Han, G. Niu, T. Liu, and M. Sugiyama" pdf="https://arxiv.org/pdf/2001.03772.pdf" conference="In ICML, 2021." highlight="Long Talk"/>
                <Paper title="Provably End-to-end Label-noise Learning without Anchor Points" authors="X. Li, T. Liu, B. Han, G. Niu, and M. Sugiyama" pdf="https://arxiv.org/pdf/2102.02400.pdf" conference="In ICML, 2021." highlight=""/>
                <Paper title="Class2Simi: A Noise Reduction Perspective on Learning with Noisy Labels" authors="S. Wu*, X. Xia*, T. Liu, B. Han, M. Gong, N. Wang, H. Liu, and G. Niu " pdf="https://arxiv.org/pdf/2006.07831.pdf" conference="In ICML, 2021." highlight=""/>
                <Paper title="Towards Defending against Adversarial Examples via Attack-Invariant Features" authors="D. Zhou, T. Liu, B. Han, N. Wang, C. Peng, and X. Gao" pdf="https://arxiv.org/pdf/2106.05036.pdf" conference="In ICML, 2021." highlight=""/>
				<Paper title="Maximum Mean Discrepancy is Aware of Adversarial Attacks " authors="R. Gao, F. Liu, J. Zhang, B. Han, T. Liu, G. Niu, M. Sugiyama" pdf="https://arxiv.org/pdf/2010.11415.pdf" conference="In ICML, 2021." highlight=""/>
				<Paper title="Learning Diverse-Structured Networks for Adversarial Robustness " authors="X. Du, J. Zhang, B. Han, T. Liu, Y. Rong, G. Niu, J. Huang, and M. Sugiyama" pdf="https://arxiv.org/pdf/2102.01886.pdf" conference="In ICML, 2021." highlight=""/>
				<Paper title="VecNet: A Spectral and Multi-scale Spatial Fusion Deep Network for Pixel-level Cloud Type Classification in Himawari-8 Imagery" authors="Z. Wang, X. Kong, Z. Cui, M. Wu, C. Zhang, M. Gong, and T. Liu" pdf="" source="https://ieeexplore.ieee.org/document/9554737" conference="In IGARSS, 2021." highlight=""/>
				<Paper title="Robust early-learning: Hindering the memorization of noisy labels. " authors="X. Xia, T. Liu, B. Han, C. Gong, N. Wang, Z. Ge, and Y. Chang." pdf="https://openreview.net/pdf?id=Eql5b1_hTE4" conference="In ICLR, 2021." highlight=""/>
                <Paper title="A Machine Learning Approach for Predicting Human Preference for Graph Layouts." authors="S. Cai, S. Hong, J. Shen, and T. Liu." pdf="https://arxiv.org/pdf/2103.03665.pdf" conference="In PacificVis, 2021." highlight="Best VisNotes Paper Award"/>
                <Paper title="Robust Dual Recurrent Neural Networks for Financial Time Series Prediction. " authors="J. He, M. Khushi, N. Tran, and T. Liu." pdf="https://epubs.siam.org/doi/pdf/10.1137/1.9781611976700.84" conference="In SDM, 2021." highlight=""/>
                <Paper title="Learning with Group Noise." authors="Q. Wang, J. Yao, C. Gong, T. Liu, M. Gong, H. Yang, and B. Han." pdf="https://arxiv.org/pdf/2103.09468.pdf" conference="In AAAI, 2021." highlight=""/>
                <Paper title="Tackling Instance-Dependent Label Noise via a Universal Probabilistic Model." authors="Q. Wang, B. Ha T. Liu, G. Niu, J. Yang, and C. Gong." pdf="https://arxiv.org/pdf/2101.05467.pdf" conference="In AAAI, 2021." highlight=""/>
                <br/><hr/><br/>

			  <h3>2020</h3>

			  <Paper title="Part-dependent Label Noise: Towards Instance-dependent Label Noise." authors="X. Xia, T. Liu, B. Han, N. Wang, M. Gong, H. Liu, G. Niu, D. Tao, and M. Sugiyama." pdf="https://arxiv.org/abs/2006.07836" conference="In NeurIPS, 2020." highlight="Spotlight"/>
              <Paper title="Dual T: Reducing Estimation Error for Transition Matrix in Label-noise Learning." authors="Y. Yao, T. Liu, B. Han, M. Gong, J. Deng, G. Niu, and M. Sugiyama." pdf="https://arxiv.org/abs/2006.07805" conference="In NeurIPS, 2020."/>
			  <Paper title="Domain Generalization via Entropy Regularization." authors="S. Zhao, M. Gong, T. Liu, H. Fu, and D. Tao." pdf= "https://papers.nips.cc/paper/2020/file/b98249b38337c5088bbc660d8f872d6a-Paper.pdf" conference="In NeurIPS, 2020."/>
			  <Paper title="Sub-center ArcFace: Boosting Face Recognition by Large-scale Noisy Web Faces." authors="J. Deng, J. Guo, T. Liu, M. Gong, and S Zafeiriou." pdf="https://www.ecva.net/papers/eccv_2020/papers_ECCV/papers/123560715.pdf" conference="In ECCV, 2020."/>
			  <Paper title="Deep Heterogeneous Multi-Task Metric Learning for Visual Recognition and Retrieval." authors="S. Gan, Y. Luo, Y. Wen, T. Liu, and H. Hu." conference="In ACM MM, 2020." source="https://dl.acm.org/doi/10.1145/3394171.3413574"/>
			  <Paper title="Learning with Bounded Instance- and Label-dependent Label Noise." authors="J. Cheng, T. Liu, K. Rao, and D. Tao." pdf="https://arxiv.org/abs/1709.03768" conference="In ICML, 2020."/>
			  <Paper title="Label-Noise Robust Domain Adaptation." authors="X. Yu, T. Liu, M. Gong, K. Zhang, K. Batmanghelich, and D. Tao." pdf="https://proceedings.icml.cc/static/paper_files/icml/2020/1942-Paper.pdf" conference="In ICML, 2020."/>
			  <Paper title="Dual-Path Distillation: A Unified Framework to Improve Black-Box Attacks." authors="Y. Zhang, Y. Li, T. Liu, and X. Tian." pdf="https://proceedings.icml.cc/static/paper_files/icml/2020/3224-Paper.pdf" conference="In ICML, 2020."/>
			  <Paper title="LTF: A Label Transformation Framework for Correcting Label Shift." authors="J. Guo, M. Gong, T. Liu, K. Zhang, and D. Tao." pdf="https://proceedings.icml.cc/static/paper_files/icml/2020/1262-Paper.pdf" conference="In ICML, 2020."/>
              <Paper title="Generative-Discriminative Complementary Learning." authors="Y. Xu, M. Gong, J. Chen, T. Liu, K. Zhang, and K. Batmanghelich." conference="In AAAI, 2020." pdf="https://batman-lab.com/sites/default/files/AAAI_complementary_learning_v4.pdf"/>
		      <Paper title="Diversified Bayesian Nonnegative Matrix Factorization." authors="M. Qiao, J. Yu, T. Liu, X. Wang, and D. Tao." pdf="https://ojs.aaai.org/index.php/AAAI/article/view/5991/5847" conference="In AAAI, 2020."/>
			  <br/><hr/><br/>

			  <h3>2019</h3>
			    <Paper title="Are Anchor Points Really Indispensable in Label-Noise Learning?" authors="Xiaobo Xia, Tongliang Liu, Nannan Wang, Bo Han, Chen Gong, Gang Niu, and Masashi Sugiyama" pdf="https://arxiv.org/abs/1906.00189" code="" project="" conference="In NeurIPS, 2019."/>
			    <Paper title="Control Batch Size and Learning Rate to Generalize Well: Theoretical and Empirical Evidence." authors="F. He, T. Liu, and D. Tao" pdf="https://papers.nips.cc/paper/8398-control-batch-size-and-learning-rate-to-generalize-well-theoretical-and-empirical-evidence.pdf" conference="In NeurIPS, 2019."/>
			    <Paper title="DistillHash: Unsupervised Deep Hashing by Distilling Data Pairs. " authors="E. Yang, T. Liu, C. Deng, W. Liu, and D. Tao." conference="In CVPR, 2019." pdf="https://arxiv.org/pdf/1905.03465.pdf"/>
			    <Paper title="Skipping Two Layers in ResNet Makes the Generalization Gap Smaller than Skipping One or No Layer." authors="Y. Furusho, T. Liu, and K. Ikeda." conference="In INNSBDDL, 2019." source="https://link.springer.com/chapter/10.1007/978-3-030-16841-4_36"/>
			    <Paper title="Positive and Unlabeled Learning with Label Disambiguation." authors="C. Zhang, D. Ren, T. Liu, J. Yang, and C. Gong." conference="In IJCAI, 2019." pdf="https://www.ijcai.org/Proceedings/2019/0590.pdf"/>
			    <Paper title="Towards Digital Retina in Smart Cities: A Model Generation, Utilization and Communication Paradigm." authors="Y. Lou, L. Duan, Y. Luo, Z. Chen, T. Liu, S. Wang, and W. Gao." conference="In ICME, 2019." pdf="https://arxiv.org/pdf/1907.13368.pdf" highlight="Best Paper Award"/>
			  <br/><hr/><br/>

			  <h3>2018</h3>
			  <Paper title="An Efficient and Provable Approach for Mixture Proportion Estimation Using Linear Independence Assumption." authors="X. Yu, T. Liu, M. Gong, K. Batmanghelich, and D. Tao." pdf="https://openaccess.thecvf.com/content_cvpr_2018/papers/Yu_An_Efficient_and_CVPR_2018_paper.pdf" conference="In CVPR, 2018."/>
			  <Paper title="Learning with Biased Complementary Labels." authors="Xiyu Yu, Tongliang Liu, Mingming Gong, and Dacheng Tao" pdf="https://openaccess.thecvf.com/content_ECCV_2018/papers/Xiyu_Yu_Learning_with_Biased_ECCV_2018_paper.pdf" conference="In ECCV, 2018."/>
			  <Paper title="Correcting the Triplet Selection Bias for Triplet Loss. " authors="B. Yu, T. Liu, M. Gong, C. Ding, and D. Tao." pdf="https://openaccess.thecvf.com/content_ECCV_2018/papers/Baosheng_Yu_Correcting_the_Triplet_ECCV_2018_paper.pdf" conference="In ECCV, 2018."/>
			  <Paper title="Deep Domain Generalization via Conditional Invariant Adversarial Networks. " authors="Y. Li, X. Tian, M. Gong, Y. Liu, T. Liu, K. Zhang, and D. Tao." pdf="https://openaccess.thecvf.com/content_ECCV_2018/papers/Ya_Li_Deep_Domain_Generalization_ECCV_2018_paper.pdf" conference="In ECCV, 2018."/>
			  <Paper title="Quantum Divide-and-Conquer Anchoring for Separable Non-negative Matrix Factorization." authors="Y. Du, T. Liu, Y. Li, R. Duan, and D. Tao." pdf="https://www.ijcai.org/Proceedings/2018/0289.pdf" conference="In IJCAI, 2018."/>
			  <Paper title="Online Heterogeneous Transfer Metric Learning." authors="Y. Luo, T. Liu, Y. Wen, and D. Tao." pdf="https://www.ijcai.org/Proceedings/2018/0350.pdf" conference="In IJCAI, 2018."/>
			  <Paper title="Semantic Structure-based Unsupervised Deep Hashing. " authors="E. Yang, C. Deng, T. Liu, W. Liu, and D. Tao." pdf="https://www.ijcai.org/Proceedings/2018/0148.pdf" conference="In IJCAI, 2018."/>
              <Paper title="Domain Generalization via Conditional Invariant Representations." authors="Y. Li, M. Gong, X. Tian, T. Liu, and D. Tao." pdf="https://arxiv.org/abs/1807.08479" conference="In AAAI, 2018"/>
              <Paper title="Robust Angular Local Descriptor Learning. " authors="Y. Xu, M. Gong, T. Liu, K. Batmanghelich, C. Wang." pdf="https://arxiv.org/abs/1901.07076" conference="In ACCV, 2018."/>
			  <br/><hr/><br/>

			  <h3>2017</h3>
			  <Paper title="Algorithmic Stability and Hypothesis Complexity." authors="T. Liu, G. Lugosi, G. Neu and D. Tao." pdf="http://proceedings.mlr.press/v70/liu17c/liu17c.pdf" conference="In ICML , 2017."/>
			  <Paper title="On Compressing Deep Models by Low Rank and Sparse Decomposition. " authors="X. Yu, T. Liu, X. Wang, and D. Tao." pdf="https://openaccess.thecvf.com/content_cvpr_2017/papers/Yu_On_Compressing_Deep_CVPR_2017_paper.pdf" conference="In CVPR, 2017."/>
		      <Paper title="Understanding How Feature Structure Transfers in Transfer Learning." authors="T. Liu, Q. Yang, and D. Tao." pdf="https://www.ijcai.org/Proceedings/2017/0329.pdf" conference="In IJCAI, 2017."/>
		      <Paper title="General Heterogeneous Transfer Distance Metric Learning via Knowledge Fragments Transfer." authors="Y. Luo, Y. Wen, T. Liu, and D. Tao." pdf="https://www.ijcai.org/Proceedings/2017/0341.pdf" conference="In IJCAI, 2017."/>
			  <br/><hr/><br/>

			  <h3>2016</h3>
			  <Paper title="Domain Adaptation with Conditional Transferable Components." authors="M. Gong, K. Zhang, T. Liu, D. Tao, C. Glymour, and B. Schölkopf." pdf="http://proceedings.mlr.press/v48/gong16.pdf" conference="In ICML, 2106."/>
		      <Paper title="Diversified Dynamical Gaussian Process Latent Variable Model for Video Repair." authors="H. Xiong, T. Liu, and D. Tao." conference="In AAAI, 2016." source="https://dl.acm.org/doi/10.5555/3016387.3016415"/>
			  <br/><hr/><br/>

			  <h3>2015</h3>
			  <Paper title="Spectral Ensemble Clustering." authors="H. Liu, T. Liu, J. Wu, D. Tao, and Y. Fu." conference="In KDD, 2015." source="https://dl.acm.org/doi/10.1145/2783258.2783287"/>
			  <Paper title="Multi-Task Model and Feature Joint Learning. " authors="Y. Li, X. Tian, T. Liu, and D. Tao." pdf="https://www.ijcai.org/Proceedings/15/Papers/512.pdf" conference="In IJCAI, 2015." highlight="Best Paper Award candidate"/>
			  <Paper title="Learning Relative Features through Adaptive Pooling for Image Classification. " authors="M. Shao, S. Li, T. Liu, D. Tao, T. S. Huang, and Y. Fu." conference="In ICME, 2014." source="https://ieeexplore.ieee.org/document/6890269"/>
              <Paper title="On the Robustness and Generalization of Cauchy Regression." authors="T. Liu and D. Tao." conference="In ICIST, 2014." source="https://ieeexplore.ieee.org/document/6920341" highlight="Best Paper Award"/>
              <br/><hr/><br/>
			</div>

			<div className="tab-pane fade" id="nav-journal" role="tabpanel" aria-labelledby="nav-journal-tab">

			<br/><br/>

				<h3>2024</h3>
				<Paper title="A Time-Consistency Curriculum for Learning from Instance-Dependent Noisy Labels." authors="S. Wu, T. Zhou, Y. Du, J. Yu, B. Han, and T. Liu." pdf="" conference="IEEE T-PAMI, accepted." source="https://ieeexplore.ieee.org/abstract/document/10418893" highlight=""/>
				<Paper title="Regularly Truncated M-estimators for Learning with Noisy Labels." authors="X. Xia, P. Lu, C. Gong, B. Han, J. Yu, J. Yu, and T. Liu." pdf="" conference="IEEE T-PAMI, accepted." source="https://ieeexplore.ieee.org/abstract/document/10375792" highlight=""/>
				<Paper title="BadLabel: A Robust Perspective on Evaluating and Enhancing Label-Noise Learning." authors="J. Zhang, B. Song, H. Wang, B. Han, T. Liu, L. Liu, and M. Sugiyama." pdf="" conference="IEEE T-PAMI, accepted." source="https://ieeexplore.ieee.org/abstract/document/10404058" highlight=""/>
				<Paper title="NoiLIn: Do Noisy Labels Always Hurt Adversarial Training?" authors="J. Zhang, X. Xu, B. Han, T. Liu, G. Niu, L.Cui, and M. Sugiyama." pdf="" conference="TMLR, Accepted." source="https://arxiv.org/abs/2105.14676" highlight=""/>
				<br/><hr/><br/>

				<h3>2023</h3>
				<Paper title="Extended T: Learning with Mixed Closed-set and Open-set Noisy Labels." authors="X. Xia, B. Han, N. Wang, J. Deng, J. Li, Y. Miao, and T. Liu." pdf="" conference="IEEE T-PAMI, 45(3): 3047-3058, 2023." source="https://ieeexplore.ieee.org/abstract/document/9790332" highlight=""/>
				<Paper title="A Parametrical Model for Instance-Dependent Label Noise." authors="S. Yang, S. Wu, E. Yang, B. Han, Y. Liu, M. Xu, G. Niu, and T. Liu." pdf="" conference="IEEE T-PAMI, 45(12): 14055-14068, 2023." source="https://ieeexplore.ieee.org/abstract/document/10209198" highlight=""/>
				<Paper title="FedDAG: Federated DAG Structure Learning." authors="E. Gao, J. Chen, L. Shen, T. Liu, M. Gong and H. Bondell." pdf="" conference="TMLR, 2023." source="https://openreview.net/forum?id=MzWgBjZ6Le&referrer=%5Bthe%20profile%20of%20Erdun%20Gao%5D(%2Fprofile%3Fid%3D~Erdun_Gao1" highlight=""/>
				<Paper title="KRADA: Known-region-aware Domain Alignment for Open-set Domain Adaptation in Semantic Segmentation." authors="C. Zhou, F. Liu, C. Gong, R. Zeng, T. Liu, W. Cheung, and B. Han." pdf="" conference="TMLR, 2023." source="https://openreview.net/pdf?id=5II12ypVQo" highlight=""/>
				<Paper title="Relation-Aware Fine-Grained Reasoning Network for Textbook Question Answering." authors="J. Ma, J. Liu, Y. Wang, J. Li, T. Liu" pdf="" conference="IEEE T-NNLS, 34(1): 15-27, 2023." source="https://ieeexplore.ieee.org/document/9466370" highlight=""/>
				<Paper title="Trustable Co-label Learning from Multiple Noisy Annotators." authors="S. Li, T. Liu, J. Tan, D. Zeng, and S. Ge." pdf="" conference="IEEE T-MM, 25: 1045-1057, 2023." source="https://arxiv.org/pdf/2203.04199.pdf#:~:text=This%20paper%20proposes%20a%20data,classifier%20and%20the%20label%20aggregator." highlight=""/>
				<Paper title="Understanding How Pre-Training Regularizes Deep Learning Algorithms." authors="Y. Yao, B. Yu, C. Gong, and T. Liu." pdf="" conference="IEEE T-NNLS, 34(9): 5828-5840, 2023." source="https://ieeexplore.ieee.org/document/9646441" highlight=""/>
				<Paper title="An Optimal Transport Analysis on Generalization in Deep Learning." authors="J. Zhang, T. Liu, and D. Tao." pdf="" conference="IEEE T-NNLS, 34(6): 2842-2853, 2023." source="https://ieeexplore.ieee.org/document/9546990" highlight=""/>
				<br/><hr/><br/>

			  <h3>2022</h3>
			    <Paper title="Transferable Coupled Network for Zero-Shot Sketch-Based Image Retrieval." authors="H. Wang, C. Deng, T. Liu, and D. Tao." pdf="" conference="IEEE T-PAMI, 44(12): 9181-9194, 2022." source="https://ieeexplore.ieee.org/document/9591307" highlight=""/>
			    <Paper title="Instance-Dependent Positive and Unlabeled Learning with Labeling Bias Estimation." authors="C. Gong, Q. Wang, T. Liu, B. Han, J. You, J. Yang, and D. Tao." pdf="" conference="IEEE T-PAMI, 44(8): 4163-4177, 2022." source="https://ieeexplore.ieee.org/document/9361303" highlight=""/>
				<Paper title="Heterogeneous Graph Attention Network for Unsupervised Multiple-Target Domain Adaptation." authors="X. Yang, C. Deng, T. Liu, and D. Tao." conference="IEEE T-PAMI, 44(4): 1992-2003, 2022." source="https://ieeexplore.ieee.org/document/9204804" highlight=""/>
			    <Paper title="Bridging the Gap between Few-Shot and Many-Shot Learning via Distribution Calibration." authors="S. Yang, S. Wu, T. Liu, and M. Xu." pdf="" conference="IEEE T-PAMI, 44(2): 9830-9843, 2022." source="https://ieeexplore.ieee.org/document/9634045" highlight=""/>
			    <Paper title="Learning from Noisy Pairwise Similarity and Unlabeled Data." authors="S. Wu, T. Liu, B. Han, J. Yu, G. Niu, and M. Sugiyama." pdf="" conference="JMLR, 23(307): 1-32, 2022." source="https://www.jmlr.org/papers/v23/21-0946.html" highlight=""/>
			    <Paper title="Quantum differentially private sparse regression learning. " authors="Y. Du, M. Hsieh, T. Liu, S. You, and D. Tao." pdf="" conference="IEEE T-IT, 68(8): 5217-5233, 2022." source="https://ieeexplore.ieee.org/stamp/stamp.jsp?arnumber=9749100" highlight=""/>
			    <Paper title="Exploring language hierarchy for video grounding." authors="X. Ding, N. Wang, S. Zhang, Z. Huang, X. Li, M. Tang, T. Liu, and X. Gao." pdf="" conference="IEEE T-IP, 31(8): 4693-4706, 2022." source="https://ieeexplore.ieee.org/stamp/stamp.jsp?arnumber=9817030" highlight=""/>
			    <Paper title="On the Rates of Convergence from Surrogate Risk Minimizers to the Bayes Optimal Classifier." authors="J. Zhang, T. Liu, and D. Tao." pdf="https://arxiv.org/pdf/1802.03688.pdf" conference="IEEE T-NNLS, 33(10): 5766-5774, 2022." source="" highlight=""/>
			    <Paper title="Improving medical image classification with label noise using dual-uncertainty estimation." authors="L. Ju, X. Wang, L. Wang, D. Mahapatra, X. Zhao, M. Harandi, T. Drummond, T. Liu, and Z. Ge." pdf="" conference="IEEE T-MI, 41(6): 1533-1546, 2022." source="https://ieeexplore.ieee.org/stamp/stamp.jsp?arnumber=9674886" highlight=""/>
			    <Paper title="LR-SVM+: Learning Using Privileged Information with Noisy Labels." authors="Z. Wu, X. Xia, R. Wang, J. Li, J. Yu, Y. Mao, and T. Liu." pdf="" conference="IEEE T-MM, 24: 1080-1092, 2022." highlight="" source="https://ieeexplore.ieee.org/document/9556575"/>
				<Paper title="TWGAN: Twin Discriminator Generative Adversarial Networks." authors="Z. Zhang, M. Li, H. Xie, J. Yu, T. Liu, and C. W. Chen." pdf="" conference="IEEE T-MM, 24: 677-688, 2022." highlight="" source="https://ieeexplore.ieee.org/document/9352520"/>
				<Paper title="Laplacian Welsch Regularization for Robust Semi-Supervised Learning." authors="J. Ke, C. Gong, T. Liu, L. Zhao, J. Yang, and D. Tao." pdf="" conference="IEEE T-CYB, 51(1): 164-177, 2022." highlight="" source="https://ieeexplore.ieee.org/document/9020208"/>
				<br/><hr/><br/>

			  <h3>2021</h3>
			  		<Paper title="Orthogonal Deep Neural Networks." authors="K. Jia, S. Li, Y. Wen, T. Liu, and D. Tao." pdf="https://arxiv.org/pdf/1905.05929.pdf" conference="IEEE T-PAMI, 43(4): 1352-1368, 2021." highlight="" source=""/>
                    <Paper title="Loss Decomposition and Centroid Estimation for Positive and Unlabeled Learning." authors="C. Gong, H. Shi, T. Liu, C. Zhang, J. Yang, and D. Tao." conference="IEEE T-PAMI, 43(3): 918-932, 2021." source="https://ieeexplore.ieee.org/document/8839365"/>
					<Paper title="A Shape Transformation-based Dataset Augmentation Framework for Pedestrian Detection." authors="Z. Chen, W. Ouyang, T. Liu, and D. Tao." conference="IJCV, 129(4): 1121-1138, 2021." source="https://arxiv.org/abs/1912.07010"/>
					<Paper title="HRSiam: High-Resolution Siamese Network, Towards Space-Borne Satellite Video Tracking." authors="J. Shao, B. Du, C. Wu, M. Gong, and T. Liu." conference="IEEE T-IP, 30(2): 3056-3068, 2021." source="https://ieeexplore.ieee.org/document/9350236"/>
                    <Paper title="KFC: An Efficient Framework for Semi-supervised Temporal Action Localization." authors="X. Ding, N Wang, X. Gao, J. Li, X. Wang, and T. Liu." conference="IEEE T-IP, 30(7): 6869-6878, 2021." source="https://ieeexplore.ieee.org/stamp/stamp.jsp?arnumber=9500051"/>
			  <br/><hr/><br/>

			  <h3>2020</h3>
				<Paper title="Absent Multiple Kernel Learning Algorithms." authors="X. Liu, L. Wang, X. Zhu, M. Li, E. Zhu, T. Liu, L. Liu, Y. Dou, and J. Yin." pdf="" conference="IEEE T-PAMI, 42(6): 1303-1316, 2020." highlight="" source="https://ieeexplore.ieee.org/document/8627941"/>
			    <Paper title="Multiple Kernel k-means with Incomplete Kernels." authors="X. Liu, X. Zhu, M. Li, L. Wang, E. Zhu, T. Liu, M. Kloft, D. Shen, J. Yin, and W. Gao." pdf="" conference="IEEE T-PAMI, 42(5): 1191-1204, 2020." highlight="" source="https://ieeexplore.ieee.org/document/8611131"/>
				<Paper title="Two-Stream Deep Hashing with Class-Specific Centers for Image Search." authors="C. Deng, E. Yang, T. Liu, and D. Tao." pdf="" conference="IEEE T-NNLS, 31(6): 2189-2201, 2020." highlight="" source="https://ieeexplore.ieee.org/document/8833511"/>
                <Paper title="Why ResNet Works? Residuals Generalize." authors="F. He, T. Liu, and D. Tao." pdf="https://arxiv.org/abs/1904.01367" conference="IEEE T-NNLS, 31(12): 5349-5362, 2020." highlight="" source="https://ieeexplore.ieee.org/document/8984747"/>
				<Paper title="Harnessing Side Information for Classification under Label Noise." authors="Y. Wei, C. Gong, S. Chen, T. Liu, J. Yang, and D. Tao." pdf="" conference="IEEE T-NNLS, 31(9): 3178-3192, 2020." highlight="" source="https://ieeexplore.ieee.org/abstract/document/8848850"/>
				<Paper title="Group Feedback Capsule Network." authors="X. Ding, N Wang, X. Gao, J. Li, X. Wang, and T. Liu." pdf="" conference="IEEE T-IP, 29: 6789-6799, 2020." highlight="" source="https://ieeexplore.ieee.org/document/9099041"/>
				<Paper title="Towards Efficient Front-end Visual Sensing for Digital Retina: A Model-Centric Paradigm." authors="Y. Lou, L. Duan, Y. Luo, Z. Chen, T. Liu, S. Wang, and W. Gao." pdf="" conference="IEEE T-MM, 22(11): 3002-3013, 2020." highlight="" source="https://ieeexplore.ieee.org/document/8960464"/>
				<Paper title="Adversarial Examples for Hamming Space Search." authors="E. Yang, T. Liu, C. Deng, and D. Tao." pdf="" conference="IEEE T-CYB, 50(4): 1473-1484, 2020." highlight="" source="https://ieeexplore.ieee.org/document/8573146"/>
			  <br/><hr/><br/>

			  <h3>2019</h3>
			  <Paper title="Transferring Knowledge Fragments for Learning Distance Metric from A Heterogeneous Domain." authors="Y. Luo, Y. Wen, T. Liu, and D. Tao." pdf="" conference="IEEE T-PAMI, 41(4): 1013-1026, 2019." highlight="" source="https://ieeexplore.ieee.org/abstract/document/8333749"/>
              <Paper title="Truncated Cauchy Non-negative Matrix Factorization." authors="N. Guan, T. Liu, Y. Zhang, D. Tao, and L. S. Davis." pdf="https://arxiv.org/abs/1906.00495" conference="IEEE T-PAMI, 41(1): 246-259, 2019." highlight=""/>
			  <Paper title="Large-Margin Label-Calibrated Support Vector Machines for Positive and Unlabeled Learning." authors="C. Gong, T. Liu, J. Yang, and D. Tao." pdf="" conference="IEEE T-NNLS, 30(11): 3471-3483, 2019." highlight="" source="https://ieeexplore.ieee.org/document/8636540"/>
			  <Paper title="Eigenfunction-Based Multitask Learning in a Reproducing Kernel Hilbert Space." authors="X. Tian, Y. Li, T. Liu, X. Wang, and D. Tao." pdf="" conference="IEEE T-NNLS, 30(6): 1818-1830, 2019." highlight="" source="https://ieeexplore.ieee.org/document/8513983"/>
              <Paper title="Adaptive Morphological Reconstruction for Seeded Image Segmentation." authors="T. Lei, X. Jia, T. Liu, S. Liu, H. Meng, and A. K. Nandi." pdf="https://arxiv.org/abs/1904.03973" conference="IEEE T-IP, 28(11): 5510-5523, 2019." highlight=""/>
			  <Paper title="Unsupervised Semantic-Preserving Adversarial Hashing for Image Search." authors="C. Deng, E. Yang, T. Liu, J. Li, W. Liu, and D. Tao." pdf="" conference="IEEE T-IP, 28(8): 4032-4044, 2019." highlight="" source="https://ieeexplore.ieee.org/document/8666767"/>
			  <br/><hr/><br/>

			  <h3>2018</h3>
			  <Paper title="Fast Supervised Discrete Hashing. " authors="J. Gui, T. Liu, Z. Sun, D. Tao, and T. Tan." pdf="" conference="IEEE T-PAMI, 40(2): 490-496, 2018." highlight="" source="https://ieeexplore.ieee.org/document/7873258"/>
              <Paper title="Continuous Dropout. " authors="X. Shen, X. Tian, F. Xu, T. Liu, and D. Tao." pdf="https://arxiv.org/abs/1911.12675" conference="IEEE T-NNLS, 29(9): 3926-3937, 2018." highlight=""/>
              <Paper title="Multi-class Learning with Partially Corrupted Labels." authors="R. Wang, T. Liu, and D. Tao." pdf="" conference="IEEE T-NNLS, 29(6): 2568-2580, 2018." highlight="" source="https://ieeexplore.ieee.org/document/7929355"/>
              <Paper title="On Better Exploring and Exploiting Task Relationship in Multi-Task Learning: Joint Model and Feature Learning." authors="Y. Li, X. Tian, T. Liu, and D. Tao." pdf="https://arxiv.org/abs/1904.01747" conference="IEEE T-NNLS, 29(5): 1975-1985, 2018." highlight=""/>
              <Paper title="Supervised Discrete Hashing with Relaxation." authors="J. Gui, T. Liu, Z. Sun, D. Tao, and T. Tan." pdf="https://arxiv.org/abs/1904.03549" conference="IEEE T-NNLS, 29(3): 608-617, 2018." highlight=""/>
              <Paper title="Deep Blur Mapping: Exploiting High-Level Semantics by Deep Neural Networks." authors="K. Ma, H. Fu, T. Liu, Z. Wang, and D. Tao." pdf="https://arxiv.org/abs/1612.01227" conference="IEEE T-IP, 27(10): 5155-5166, 2018." highlight=""/>
              <Paper title="A Regularization Approach for Instance-Based Superset Label Learning." authors="C. Gong, T. Liu, Y. Tang, J. Yang, J. Yang, and D. Tao." pdf="https://arxiv.org/pdf/1904.02832.pdf" conference="IEEE T-CYB, 48(3): 967-978, 2018." highlight=""/>
			  <br/><hr/><br/>

              <h3>2017</h3>

			    <Paper title="Algorithm-Dependent Generalization Bounds for Multi-Task Learning." authors="T. Liu, D. Tao, M. Song, and S. J. Maybank." pdf="" conference="IEEE T-PAMI, 39(2): 227-241, 2017." highlight="" source="https://ieeexplore.ieee.org/document/7437460"/>
			    <Paper title="Large Cone Non-negative Matrix Factorization." authors="T. Liu, M. Gong, and D. Tao." pdf="" conference="IEEE T-NNLS, 28(9): 2129-2141, 2017." highlight="" source="https://ieeexplore.ieee.org/document/7492255"/>
			    <Paper title="dipIQ: Blind Image Quality Assessment by Learning-to-Rank Discriminable Image Pairs." authors="K. Ma, W. Liu, T. Liu, Z. Wang, and D. Tao." pdf="https://arxiv.org/pdf/1904.06505.pdf" conference="IEEE T-IP, 26(8): 3951-3964, 2017." highlight=""/>
			    <Paper title="Elastic Net Hypergraph Learning for Image Clustering and Semi-supervised Classification." authors="Q. Liu, Y. Sun, C. Wang, T. Liu, and D. Tao." pdf="" conference="IEEE T-IP, 26(1): 452-463, 2017." highlight="" source="https://ieeexplore.ieee.org/document/7707352"/>
			    <Paper title="Spectral Ensemble Clustering via Weighted K-means: Theoretical and Practical Evidence." authors="H. Liu, J. Wu, T. Liu, D. Tao, and Y. Fu." pdf="" conference="IEEE T-KDE, vol. 29, no. 5, pp. 1129-1143, 2017." highlight="" source="https://ieeexplore.ieee.org/document/7811216"/>
			    <Paper title="Joint Sparse Representation and Multitask Learning for Hyperspectral Target Detection." authors="Y. Zhang, B. Du, L. Zhang, and T. Liu." pdf="" conference="IEEE T-GRS, 55(2): 894-906, 2017." highlight="" source="https://ieeexplore.ieee.org/document/7769257"/>
			  <br/><hr/><br/>

                <h3>2016</h3>
			    <Paper title="Classification with Noisy Labels by Importance Reweighting." authors="Tongliang Liu and Dacheng Tao" pdf="https://arxiv.org/pdf/1411.7718.pdf" conference="IEEE T-PAMI, 38(3): 447-461, 2016."/>
			    <Paper title="Dimensionality-Dependent Generalization Bounds for k-Dimensional Coding Schemes." authors="T. Liu, D. Tao, and D. Xu." pdf="https://arxiv.org/pdf/1601.00238.pdf" conference="NECO, 28(10): 2213-2249, 2016." highlight=""/>
			    <Paper title="On the Performance of MahNMF Manhattan Non-negative Matrix Factorization." authors="T. Liu and D. Tao." pdf="" conference="IEEE T-NNLS, 27(9): 1851-1863, 2016." highlight="" source="https://ieeexplore.ieee.org/document/7192641"/>
                <Paper title="Dual Diversified Dynamical Gaussian Process Latent Variable Model for Video Repair." authors="H. Xiong, T. Liu, D. Tao, and H. T. Shen." pdf="" conference="IEEE T-IP, 25(8): 3626-3637, 2016." highlight="" source="https://ieeexplore.ieee.org/document/7479456"/>
                <Paper title="Local Rademacher Complexity for Multi-Label Learning. " authors="C. Xu, T. Liu, D. Tao, and C. Xu." pdf="https://arxiv.org/pdf/1410.6990.pdf" conference="IEEE T-IP, 25(3): 1495-1507, 2016." highlight=""/>
                <Paper title="Representative Vector Machines: A Unified Framework for Classical Classifiers." authors="J. Gui, T. Liu, D. Tao, Z. Sun, and T. Tan." pdf="" conference="IEEE T-CYB, 46(8): 1877-1888, 2016." highlight="" source="https://ieeexplore.ieee.org/document/7194763"/>
                <Paper title="Video Face Editing Using Temporal-Spatial-Smooth Warping." authors="X. Li, T. Liu, J. Deng, and D. Tao." pdf="" conference="ACM T-IST, 7(3): 32, 2016." highlight="" source="https://dl.acm.org/doi/10.1145/2819000"/>
			  <br/><hr/><br/>

			  <h3>2015</h3>
			  <Paper title="Deformed Graph Laplacian for Semisupervised Learning." authors="C. Gong, T. Liu, D. Tao, K. Fu, E. Tu, and J. Yang." pdf="" conference="IEEE T-NNLS, 26(10): 2261-2274, 2015." highlight="" source="https://ieeexplore.ieee.org/document/7010929"/>
              <Paper title="Multiview Matrix Completion for Multilabel Image Classification." authors="Y. Luo, T. Liu, D. Tao, and C. Xu." pdf="https://arxiv.org/abs/1904.03901" conference="IEEE T-IP, 24(8): 2261-2274, 2015." highlight=""/>
              <Paper title="No Reference Quality Assessment for Multiply-Distorted Images Based on an Improved Bag-of-Words Model." authors="Y. Lu, F. Xie, T. Liu, Z. Jiang, and D. Tao." pdf="" conference="IEEE SPL, 22(10): 1811-1815, 2015." highlight="" source="https://ieeexplore.ieee.org/document/7112105"/>
			  <br/><hr/><br/>

			  <h3>2014</h3>
              <Paper title="Decomposition-Based Transfer Distance Metric Learning for Image Classification." authors="Y. Luo, T. Liu, D. Tao, and C. Xu." pdf="https://arxiv.org/abs/1904.03846" conference="IEEE T-IP, 23(9): 3789-3801, 2014." highlight=""/>
			  <Paper title="" authors="" pdf="" conference="" highlight=""/>
			  <br/><hr/><br/>
			</div>
		    </div>

		</div>

		</div>
	    </div>
	</div>
    );
}

export default Research;
