import React from 'react';
import './News.css';
import NewsItem from './NewsItem';
import HeadWhite from './HeadWhite';
import Banner from './Banner';

class News extends React.Component{

    render() {
        return (
            <div>
	      <HeadWhite />
	      <Banner title="NEWS" />
	      <div className="container justify-content-md-center news_content">
	        <div className="row w-100 justify-content-center align-items-center">
	          <div className="col-12 col-md-10">


                    {/* 1 Team, 2 Talk, 3 Grant, 4 Award, 5 Paper  6 Service */}


                    <div className="news_content_year">
		                <h3>2024</h3>
		                </div>
                    <NewsItem type="2" date="02/2024" content="Dr Tongliang Liu was invited to give a talk at DARE 2024 Symposium."/>
                    <NewsItem type="4" date="02/2024" content={<span>Dr Tongliang Liu is honoured to be named an AI Future Leader by AI Magazine: <a href="https://aimagazine.com/top10/top-10-future-ai-leaders">"Top 10: Future AI Leaders"</a >.</span>}/>
                    <NewsItem type="5" date="02/2024" content="We have fourteen papers been accepted by ICLR 2024. Congrats to Cong, Runqi, Jiyang, Xiu-chan, Suqin and Ziming!"/>
                    <NewsItem type="2" date="01/2024" content="Dr Tongliang Liu was invited to give a talk at IEEE Sponsored 14th International Conference- Confluence 2024."/>

                    <br/><hr/><br/>

                    <div className="news_content_year">
		                <h3>2023</h3>
		                </div>
                    <NewsItem type="4" date="12/2023" content={<span>Dr Tongliang Liu was selected as a notable Area Chair for NeurIPS 2023.</span>}/>
                    <NewsItem type="4" date="12/2023" content={<span>Dr Tongliang Liu is honoured to receive the <a href="https://www.core.edu.au/awards">CORE Award for Outstanding Research Contribution</a >.</span>}/>
                    <NewsItem type="2" date="11/2023" content="Dr Tongliang Liu was invited to give a talk at RIKEN AIP."/>
                    <NewsItem type="4" date="11/2023" content={<span>Muyang Li Received the Best Poster Award in ML and AI from the School of Computer Science! Congrats Muyang!</span>}/>
                    <NewsItem type="5" date="10/2023" content="We have eleven papers been accepted by NeurIPS 2023. Congrats to Yingbin, Zhuo, Runnan, Muyang, Runqi and Yexiong!"/>
                    <NewsItem type="4" date="09/2023" content={<span>Yang Zhou got the University Medal! Congrats Yang!</span>}/>
                    <NewsItem type="4" date="09/2023" content={<span>Junzhi Ning got the University Medal! Congrats Junzhi!</span>}/>
                    <NewsItem type="4" date="07/2023" content={<span>Jiacheng Zhang got the University Medal! Congrats Jiacheng!</span>}/>
                    <NewsItem type="5" date="07/2023" content="We have eight papers been accepted by ICCV 2023. Well done Xiaobo, Suqin and Huaxi!"/>
                    <NewsItem type="4" date="07/2023" content={<span>Dr Tongliang Liu is honoured to be shortlist for the <a href="https://australian.museum/get-involved/eureka-prizes/eureka-prizes-archive/2023-eureka-prizes-finalists/">Eureka Prize for Emerging Leader in Science</a >.</span>}/>
                    <NewsItem type="4" date="05/2023" content={<span>Dr Tongliang Liu is honoured to receive the <a href="https://www.computer.org/publications/tech-news/insider/2022-ais-top-10-to-watch">IEEE AI's 10 to Watch Award</a > by the IEEE Computer Society.</span>}/>
                    <NewsItem type="5" date="05/2023" content="We have nine papers been accepted by ICML 2023. Well done Yu and Dawei!"/>
                    <NewsItem type="6" date="04/2023" content={<span>We have organised the <a href="https://mbzuai.ac.ae/events/category/virtual/">MBZUAI-RIKEN AIP joint workshop on intelligent systems</a >.</span>}/>
                    <NewsItem type="4" date="03/2023" content={<span>Muyang Li got the Top Final Year High Honour Roll! Congrats Muyang!</span>}/>
                    <NewsItem type="4" date="03/2023" content={<span>Dr Tongliang Liu was selected as a notable Area Chair for ICLR 2023.</span>}/>
                    <NewsItem type="5" date="02/2023" content="We have four papers been accepted by CVPR 2023. Congrats to Zhuo!"/>
                    <NewsItem type="5" date="02/2023" content="We have nine papers been accepted by ICLR 2023. Congrats to Xiaobo, Zhaoqing, Zhuo and Xiang!"/>
                    <NewsItem type="3" date="01/2023" content="ARC Linkage Project 2023 on Self-supervised feature learning for rapid processing of marine imagery (Dr Tongliang Liu is the Co-CI) has been funded."/>
                    <NewsItem type="3" date="01/2023" content="ARC Linkage Project 2023 on Visual methods for advanced automation of underwater manipulation (Dr Tongliang Liu is the Co-CI) has been funded."/>

                    <br/><hr/><br/>

                    <div className="news_content_year">
		                <h3>2022</h3>
		                </div>
                    <NewsItem type="2" date="10/2022" content="Dr Tongliang Liu was invited to give a talk at Conference on Information and Knowledge Management (CIKM) 2022."/>
                    <NewsItem type="5" date="10/2022" content="We have eleven papers been accepted by NeurIPS 2022. Congrats to Yingbin, Xiaobo, De and Shikun!"/>
                    <NewsItem type="4" date="09/2022" content={<span>Dr Tongliang Liu was selected as aa notable Area Chair for ICLR 2023.</span>}/>
                    <NewsItem type="4" date="09/2022" content={<span>Dr Tongliang Liu was selected as an Australian Research Council Future Fellow (only three researchers across Australia was awarded in the field of Information and Computing Sciences in 2022).</span>}/>
                    <NewsItem type="3" date="09/2022" content="ARC Future Fellowship 2023 on Modelling Adversarial Noise for Trustworthy Data Analytics (Dr Tongliang Liu is the lead CI) has been funded."/>
                    <NewsItem type="6" date="09/2022" content={<span>Dr Tongliang Liu was appointed as a Visiting Professor with University of Science and Technology of China.</span>}/>
                    <NewsItem type="6" date="09/2022" content={<span>Dr Tongliang Liu was appointed as a Visiting Associate Professor with Mohammed Bin Zayed University of Artificial Intelligence.</span>}/>
                    <NewsItem type="6" date="08/2022" content={<span>Dr Tongliang Liu is in the editorial board of <a href="https://www.jmlr.org/editorial-board.html">JMLR</a >. </span>}/>
                    <NewsItem type="6" date="08/2022" content={<span>Dr Tongliang Liu was elected as one of the editorial board of the <a href="https://link.springer.com/journal/10994/editors">ML</a > journal. </span>}/>
                    <NewsItem type="4" date="08/2022" content="Two of my PhD students have got Google PhD Fellowship Awards. Congrats Xiaobo and Shuo!"/>
                    <NewsItem type="2" date="07/2022" content="Dr Tongliang Liu was invited to give a talk at VALSE (Vision And Learning SEminar)."/>
                    <NewsItem type="6" date="07/2022" content="Dr Tongliang Liu accepted the invitation to serve as an SPC for AAAI 2023."/>
                    <NewsItem type="5" date="05/2022" content="We have five papers been accepted by ICML 2022. Well done Chaojian, Dawei, Shuo!"/>
                    <NewsItem type="6" date="07/2022" content="Dr Tongliang Liu accepted the invitation to serve as a Discussant for UAI 2022."/>
                    <NewsItem type="4" date="04/2022" content="Dr Tongliang Liu was selected as one of Global Top Young Chinese Scholars in AI (Top 25 in Machine Learning Area) by Baidu Scholar 2022."/>
                    <NewsItem type="6" date="04/2022" content="Dr Tongliang Liu accepted the invitation to serve as a Session Chair for ICLR 2022."/>
                    <NewsItem type="6" date="03/2022" content={<span>Dr Tongliang Liu co-organised <a href="http://competition.noisylabels.com">IJCAI 2022 Challenge on Learning with Noisy Labels</a >. </span>}/>
                    <NewsItem type="6" date="03/2022" content="Dr Tongliang Liu accepted the invitation to serve as an Area Chair for NeurIPS 2022."/>
                    <NewsItem type="2" date="02/2022" content="Dr Tongliang Liu was invited to give a talk at the 34th Australasian Joint Conference on Artificial Intelligence (AJCAI)."/>
                    <NewsItem type="4" date="02/2022" content="James Wood got the University Medal! Congrats James!"/>
                    <NewsItem type="5" date="02/2022" content="We have seven papers been accepted by CVPR 2022. Congrats to Erkun and Zhaoqing!"/>
                    <NewsItem type="5" date="02/2022" content="We have eight papers been accepted by ICLR 2022. Congrats to Yu and Xiaobo!"/>
                    <NewsItem type="5" date="02/2022" content="Dr Tongliang Liu's monograph on learning with noisy labels has been accepted by MIT Press."/>
                    <NewsItem type="6" date="01/2022" content="Dr Tongliang Liu accepted the invitation to serve as an Action Editor of Transactions on Machine Learning Research (TMLR), a complement to Journal of Machine Learning Research (JMLR)."/>
                    <NewsItem type="6" date="01/2022" content="Dr Tongliang Liu accepted the invitation to serve as an Area Chair for ICPR 2022."/>
                    <NewsItem type="6" date="01/2022" content="Dr Tongliang Liu co-organised TrustML Young Scientist Seminars."/>
                    <NewsItem type="1" date="01/2022" content="Mr Yuhao Wu, Mr Cong Lei and Mr Zhengning Wu joined us as PhD candidates. Welcome!"/>
                    <NewsItem type="3" date="01/2022" content="We received an industry grant from JD.com on “JD-USYD Joint AI program”."/>
                    <br/><hr/><br/>

                    <div className="news_content_year">
		                <h3>2021</h3>
		                </div>
                    <NewsItem type="3" date="12/2021" content="ARC discovery project 2022 on Transfer Learning Handling Causally Bilateral Shift (Dr Tongliang Liu is the lead CI) has been funded."/>
                    <NewsItem type="6" date="12/2021" content="Dr Tongliang Liu accepted the invitation to serve as an Area Chair for ICML 2022."/>
                    <NewsItem type="4" date="12/2021" content="Dr Tongliang Liu received the Early Career Research Excellence – Faculty of Engineering, University of Sydney."/>
                    <NewsItem type="6" date="12/2021" content="Dr Tongliang Liu accepted the invitation to serve as an Meta Reviewer for ACM KDD 2022."/>
                    <NewsItem type="6" date="11/2021" content="Dr Tongliang Liu accepted the invitation to serve as an Area Chair for UAI 2022. He also served as an AC (Senior Meta-reviewer) for IJCAI 2021."/>
                    <NewsItem type="4" date="10/2021" content="Dr Tongliang Liu received the Best Workshop Paper Runner-up Award – Masked Face Recognition Challenge and Workshop, CVPR 2021."/>
                    <NewsItem type="5" date="10/2021" content="We have five papers been accepted by NeurIPS 2021. Congrats to Yu and Yingbin!"/>
                    <NewsItem type="1" date="09/2021" content="Mr Xuan Tong joined us as PhD candidates. Welcome!"/>
                    <NewsItem type="4" date="09/2021" content="We received the Quantification of Uncertainties in Biomedical Image Quantification Challenge Award (2nd place) – MICCAI 2021."/>
                    <NewsItem type="6" date="07/2021" content="Dr Tongliang Liu accepted the invitation to serve as an Area Chair for AAAI 2022."/>
                    <NewsItem type="5" date="07/2021" content="We have two papers been accepted by ICCV 2021. Well done Dawei and Yingbin!"/>
                    <NewsItem type="6" date="06/2021" content="Dr Tongliang Liu accepted the invitation to serve as an Area Chair for ICLR 2022."/>
                    <NewsItem type="5" date="05/2021" content="We have six papers been accepted by ICML 2021. Well done Xuefeng, Dawei, Songhua, Xiaobo!"/>
                    <NewsItem type="4" date="04/2021" content="Dr Tongliang Liu received the Best VisNotes Paper Award – IEEE PacificVis 2021."/>
                    <NewsItem type="6" date="04/2021" content={<span>We are organising a <a href="https://wsl-workshop.github.io/MLJ_WSRL_CFP.pdf/">speical issue</a > at the <a href="https://link.springer.com/journal/10994">ML</a > Journal.</span>} />
                    <NewsItem type="6" date="04/2021" content={<span>We are organising the Weakly Supervised Representation Learning (WSRL) tutorial at <a href="https://ijcai-21.org/">IJCAI 2021</a >. Welcome to participate! </span>} />
                    <NewsItem type="6" date="04/2021" content={<span>We are organising a Special Issue on <a href="https://wsl-workshop.github.io/MLJ_WSRL_CFP.pdf"> weakly supervised representation learning</a > @ <a href="https://www.springer.com/journal/10994">Machine Learning</a >, Welcome to contribute!</span >} />
                    <NewsItem type="6" date="03/2021" content={<span>We are organising <a href="https://wsl-workshop.github.io/ijcai21.html"> the Weakly Supervised Representation Learning (WSRL) workshop </a > at <a href="https://ijcai-21.org/">IJCAI 2021</a >. Welcome to contribute! </span >} />
                    <NewsItem type="6" date="03/2021" content="Dr Tongliang Liu accepted the invitation to serve as an Area Chair for NeurIPS 2021."/>
                    <NewsItem type="6" date="02/2021" content={<span>We are organising <a href="https://ajml2021.github.io/"> the first Australia-Japan Workshop on Machine Learning</a >. </span>} />
                    <NewsItem type="3" date="01/2021" content="We received an the RIKEN collaborative research funding on “Development of Trustworthy Machine Learning Algorithms”."/>
                    <NewsItem type="3" date="01/2021" content="We received an industry grant from ZhanDa on “Outdoor Image Processing with Noisy Supervision Information”."/>
                    <br/><hr/><br/>

                    <div className="news_content_year">
		                <h3>2020</h3>
		                </div>
                    <NewsItem type="2" date="12/2020" content={<span> <a href="https://bhanml.github.io/" target="_blank" rel="noopener noreferrer">Dr Bo Han</a> (Assistant Professor, Hong Kong Baptist University) gives us a talk on trustworthy representation learning. </span>} />
                    <NewsItem type="2" date="12/2020" content={<span> <a href="https://weiyc.github.io/" target="_blank" rel="noopener noreferrer">Dr Yunchao Wei</a> (Lecturer, UTS) gives us a talk on visual segmentation. </span>} />
                    <NewsItem type="4" date="12/2020" content="Dr Tongliang Liu received the CVI Bioengineering and Digital Science Catalyst Award for the project “Trustworthy learning for advancing cardiovascular disease research at cellular resolutions”."/>
                    <NewsItem type="2" date="11/2020" content="Dr Tongliang Liu was invited to give a talk at The International Conference on Digital Image Computing: Techniques and Applications (DICTA)."/>
                    <NewsItem type="4" date="11/2021" content={<span>We co-organized the ACML 2020 Workshop on <a href="https://wsl-workshop.github.io/acml20.html">Weakly-supervised Representation Learning</a >. Prof. Kun Zhang from CMU and Dr Aditya Menon from Google are invited to give keynote talks. </span>} />
                    <NewsItem type="3" date="11/2020" content="We receive a Research Foundation of Cerebral Palsy Alliance Grant for developing “Highly Personalized Wearable Assistive Technologies to Enable Social Interactions for People With Complex Communication Needs”, supported by Neurodisability Assist Trust."/>
                    <NewsItem type="1" date="10/2020" content="Mr Yingbin Bai joined us as a PhD candidat. Welcome!"/>
                    <NewsItem type="3" date="10/2020" content="We received an industry grant from Meituan on “Weakly supervised or unsupervised machine learning algorithms for UAV image analysis”."/>
                    <NewsItem type="4" date="09/2021" content={<span>Dr Tongliang Liu was named in the <a href="https://specialreports.theaustralian.com.au/1540291/27/"> Early Achievers Leadboard of Engineering and Computer Science</a > by the Australian in 2020. </span>} />
                    <NewsItem type="5" date="09/2020" content="Our work on reducing the estimation error of the noisy label transition matrix and dealing with instance-dependent label noise have been accepted by NeurIPS 2020. Congrats Xiaobo and Yu!"/>
                    <NewsItem type="1" date="09/2020" content="Mr Xiaobo Xia and Mr Chaojian Yu will join us as PhD candidates. Welcome!"/>
                    <NewsItem type="2" date="08/2020" content="Dr Tongliang Liu was invited to give a talk at RIKEN AIP."/>
                    <NewsItem type="6" date="08/2020" content="Dr Tongliang Liu accepted the invitation to serve as an Area Chair for IJCAI 2021."/>
                    <NewsItem type="2" date="04/2020" content="Dr Tongliang Liu was invited to give a talk at VALSE (Vision And Learning SEminar)."/>
                    <NewsItem type="1" date="03/2020" content="Mr Songhua Wu joined our group as a PhD candidate. Welcome!"/>
                    <NewsItem type="2" date="01/2020" content="Dr Tongliang Liu was invited to give a talk at Peking University for the second USYD-PKU collaboration workshop."/>
                    <NewsItem type="2" date="01/2020" content="Dr Tongliang Liu was invited to give a talk at 4Paradigm (link http://www.4paradigm.com/), Beijing China. 4Paradigm is an AI technology that helps enterprises to improve work efficiency, reduce risks and achieve greater commercial values."/>
                    <NewsItem type="2" date="01/2020" content="Dr Tongliang Liu was invited to give a talk on label-noise learning at Institute of Information Engineering, Chinese Academy of Science, Beijing China."/>
                    <NewsItem type="3" date="01/2020" content="ARC Industrial Transformation Training Centre 2020 on Data Analytics for Resources and Environment (Dr Tongliang Liu is the Co-CI) has been funded."/>
                    <NewsItem type="3" date="01/2020" content="Faculty of Engineering Early Career Researcher Development Scheme 2020 on Algorithmic Hypothesis Complexity of Deep Learning Algorithms (Dr Tongliang Liu is the lead CI) has been funded."/>

	          </div>
	        </div>
	      </div>
	    </div>
        );

    }

}

export default News;
