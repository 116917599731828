import arc from './images/ARC.png';
import news1 from './images/top-news1.png';
import news2 from './images/top-news2.jpeg';
import news3 from './images/top-news3.jpeg';
import news4 from './images/top-news4.jpg';
import news5 from './images/top-news5.png';
import news6 from './images/top-news6.png';


import qubiq from './images/qubiq2021.jpg';
// import icml from './images/ICML.png';
// import neurips from './images/NeurIPS.png';



var newsData = [
    {
        title:"ARC Future Fellowship 2023 on Modelling Adversarial Noise for Trustworthy Data Analytics led by Dr Tongliang Liu has been funded.",
        image_url: arc,
        link:"https://www.arc.gov.au/sites/default/files/2022-08/FT22_nit_statements.pdf"
    },
    {
        title:"Dr Tongliang Liu accepted the invitation to serve as Co-Editor-in-Chief of Neural Networks.",
        image_url: news4,
        link:"https://www.sciencedirect.com/journal/neural-networks"
    },
    {
        title:"Dr Tongliang Liu is honoured to receive the IEEE AI's 10 to Watch Award by the IEEE Computer Society.",
        image_url: news5,
        link:"https://www.computer.org/publications/tech-news/insider/2022-ais-top-10-to-watch"
    },
    {
        title:"Dr Tongliang Liu is honoured to receive the CORE Award for Outstanding Research Contribution.",
        image_url: news6,
        link:"https://www.core.edu.au/awards"
    },
    {
        title:"Dr Tongliang Liu was named in the Early Achievers Leadboard of Engineering and Computer Science by The Australian in 2020.",
        image_url: news1,
        link:"https://specialreports.theaustralian.com.au/1540291/27/"
    },
    {
        title:"Dr Tongliang Liu was appointed as a visiting scientist with RIKEN AIP, Japan.",
        image_url: news2,
        link:"https://aip.riken.jp/labs/generic_tech/imperfect_inf_learn/?lang=en"
    },

    {
        title:"Dr Tongliang Liu was invited to visit Peking University for the second USYD-PKU collaboration workshop.",
        image_url: news3,
        link:""
    },
    {
        title:"We got the 2nd place in the Quantification of Uncertainties in Biomedical Image Quantification Challenge – MICCAI 2021. Congrats to Yingbin!",
        image_url: qubiq,
        link:"https://qubiq21.grand-challenge.org/"
    },

];

export default newsData;
