import React from 'react';
import './App.css';

import Team from './Team';
import TeamMember from './TeamMember';
import Home from './Home';
import HeadWhite from './HeadWhite';
import Banner from './Banner';
import Bottom from './Bottom';
import Research from './Research';
import Topics from './Topics';
import Datasets from './Datasets';
import Models from './Models';
import News from './News';
import TestPage from './TestPage';
import SIT from './images/SIT.jpg';

import {
    Switch,
    Route,
    BrowserRouter,
} from "react-router-dom";


function App() {
    return (
	<BrowserRouter basename={process.env.PUBLIC_URL}>
	<div>
	    <Switch>
              <Route exact path="/">
		<Home />
                <Bottom />
	      </Route>
	      <Route path="/research">
		<Research />
                <Bottom />
	      </Route>
	      <Route path="/Models">
		<Models />
                <Bottom />
	      </Route>
	      <Route path="/Datasets">
		<Datasets />
                <Bottom />
	      </Route>
              <Route path="/topics">
		<Topics />
                <Bottom />
	      </Route>
	      <Route path="/team">
		<Team />
                <Bottom />
	      </Route>
              <Route path="/news">
		<News />
                <Bottom />
	      </Route>
	      <Route path="/member">
		<TeamMember />
                <Bottom />
	      </Route>
	      <Route path="/about">
		<About />
                <Bottom />
	      </Route>
              <Route path="/atest">
		<TestPage />
	      </Route>
	    </Switch>
	</div>
	</BrowserRouter>
    );

}



function About() {
    return (
	<div>
	  <HeadWhite />
	  <Banner title="ABOUT US" />
	  <div className="about-content justify-content-md-center">
	    <div className="row w-100 justify-content-center align-items-center">
	      <div className="col-12 col-md-12 about-text">
                <img src={SIT} className="about-image" alt="SIT building" align="right" width="400" hspace="15" vspace="15"/>
                <h5>About the TML Lab</h5>

                <p>
                  Founded in 2020, together with industry partners, the Trustworthy Machine Learning Lab (TML Lab) at the University of Sydney hosts, attracts, and connects the best global talents to develop trustworthy machine learning techniques and tools, which are explainable, robust, fair, causally responsible, and privacy-preserving. The mission is to make machines trustworthy, which is a foundation for our society to develop and deploy artificial intelligence to improve our lives.
                </p>
                <br/> <br/>

                <h5>For prospective students</h5>
                <p>
                We are always seeking for research students with great potential to join our TML Lab. For prospective PhD, MPhil, or Honours candidate, please send A/Prof. Tongliang Liu an email (<a href="mailto:tml.laboratory@gmail.com">tml.laboratory@gmail.com</a>) with your CV, transcript, and papers (if have). We also welcome self-funded visitors to join us (or remotely due to COVID-19). We expect the prospective students to have true enthusiasm for research, backgrounds in mathematics, and strong skills in coding. A lot of time and efforts are expected to devote to the training program.  We will supervise the candidate hunting problems, thinking critically, and publishing paper by solving the major problems. We will also provide strong support to students for collaborations with world-leading scientists and engineers.
                </p>
                <br/>
                Some information about scholarships:<br/>
                <ul>
                  <li>For both domestic and international PhD students, you can apply for <a href="https://sydney.edu.au/scholarships/international/postgraduate-research.html">the RTP scholarship or the Faculty scholarship</a>. There is no specific deadline for the application. I would help you assess the probability to get a scholarship.</li>
                  <li>For international PhD students, you can also consider applying for some <a href="https://sydney.edu.au/scholarships/international/postgraduate-research/general.html">general scholarships</a>. There are strict deadlines for the scholarships. For example, the <a href="https://sydney.edu.au/scholarships/e/china-scholarship-council-research-programs-scholarship.html">CSC-USYD</a> scholarship has a deadline normally in December.</li>
                  <li>For all students, there are also many other scholarships to explore. We are happy to supervise outstanding students who have the capability to receive scholarships.</li>
                </ul>
                <br/> <br/>


                <h5>About the PI</h5>
                <p>
                  Dr Tongliang Liu is an Associate Professor with the School of Computer Science and the Director of Sydney AI Centre at the University of Sydney. He is broadly interested in the fields of trustworthy machine learning and its interdisciplinary applications, with a particular emphasis on learning with noisy labels, adversarial learning, causal representation learning, transfer learning, unsupervised learning, and statistical deep learning theory. He has authored and co-authored more than 200 research articles including ICML, NeurIPS, ICLR, CVPR, ICCV, ECCV, AAAI, IJCAI, JMLR, and TPAMI. He is/was a (senior-) meta reviewer for many conferences, such as ICML, NeurIPS, ICLR, UAI, AAAI, IJCAI, and KDD, and was a notable AC for NeurIPS and ICLR. He is a co-Editor-in-Chief for Neural Networks, an Associate Editor of TMLR and ACM Computing Surveys, and is on the Editorial Boards of JMLR and MLJ. He is a recipient of CORE Award for Outstanding Research Contribution in 2024, the IEEE AI’s 10 to Watch Award in 2022, the Future Fellowship Award from Australian Research Council (ARC) in 2022, the Top-40 Early Achievers by The Australian in 2020, and the Discovery Early Career Researcher Award (DECRA) from ARC in 2018.
                </p>
                <br/> <br/>

                <h5>About USYD</h5>
                <p>
                  The University of Sydney is the Australia’s first university built in 1850. It is a world-leading university ranked 40th in the world by the 2020 QS world university rankings. It is also one of the most beautiful universities with amazing sandstone buildings. Five Nobel Laureates have been affiliated with the university as graduates and faculty. The School of Computer Science is one of Australia’s leading computer science departments ranked 42th in the world by the 2020 QS computer science rankings. The school hosts three Fellow of the Australian Academy of Science, five ACM/IEEE Fellows and many ARC Laureate/Future/DECRA Fellows.
                </p>
                <br/> <br/>


                <h5>The City of Sydney</h5>
                <p>
                  Sydney, the capital of New South Wales, Australia. It is the oldest and largest city in Australia with a population of about 4 million which is built around a huge harbour. In Sydney, you could taste delicious food from different regions; you could visit the place with different architectural styles; you could meet new friends with diverse cultural backgrounds. Sydney is one of the most beautiful cities in the world. There are well over 100 beaches and more than 200 National Parks, and each site provides you a different experience by its unique characteristics. Sydney attracts most of the biggest acts in music and entertainment. Sydneysiders could see all of their favourite acts without having to travel halfway around the world. If you have a chance, please must come and experience the colorful life in Sydney!
                </p>
                <br/><br/><br/><br/>

	      </div>
	    </div>
	  </div>
	</div>
    );
}


export default App;
