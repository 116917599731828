// import './Team.css';
import React from 'react';
import HeadWhite from './HeadWhite';
import Banner from './Banner';
import Person from './Person';
import PersonLink from './PersonLink';
import './Team.css';
import Spacer from 'react-add-space';

import p1 from './images/tongliang.jpeg';
// import p2 from './images/Person_blank.jpeg';
import xinyu from './images/Xinyu.png';
import erkun from './images/Erkun.jpeg';
import yuxuan from './images/Yuxuan.jpeg';
import chencheng from './images/ChenCheng.jpeg';
import yingbin from './images/Yingbin.jpg';
import sonhua from './images/sonhua.jpeg';
import yuyao from './images/yuyao.jpeg';
import shijun from './images/shijun.jpg';
import jialiang from './images/jialiang.png';
import xiaobo from './images/XiaoboXia.jpg';
import kangzhao from './images/kangzhao.jpeg';
import xuefeng from './images/xuefeng.jpg';
import chaojian from './images/ChaojianYu.jpeg';
import zhengning from './images/zhengning.jpg';
import Yuhao from './images/Yuhao.jpeg';
import jerry from './images/Jerry.jpg';
import Kewei from './images/Kewei.jpeg';
// import rihui from './images/Rihui.jpg';
// import Michael from './images/Michael.jpeg';
import James from './images/James.jpg';
import Jeremy from './images/Jeremy.jpg';
import dawei from './images/Dawei.jpeg';
import Vinoth from './images/Vinoth.png';
import Joshua from './images/Joshua.jpeg';
import Jazlyn from './images/Jazlyn.jpeg';
import Matthew from './images/Matthew.jpeg';
import Yang from './images/Yang.png';
import Zhaoqing from './images/Zhaoqing.png';
import YuhaoWu from './images/YuhaoWu.jpeg';
import Pengqian from './images/Pengqian.jpg';
import Cong from './images/Cong.jpg';
import Shikun from './images/Shikun.jpg';
import Xuan from './images/Xuan.jpg';
import Runlin from './images/Runlin.jpg';
import Nguyen from './images/Nguyen.jpg';
import Yexin from './images/Yexin.jpg';
import Muyang from './images/Muyang.png';
import Huaxi from './images/Huaxi.jpeg';
import Waugh from './images/Waug.jpg';

import Jiacheng from './images/Jiacheng.png';
import Zhouyang from './images/Yang.jpg';
import Junzhi from './images/Junzhi.png';
import Kanghui from './images/Kanghui.png';
import Huangzhuo from './images/Huangzhuo.png';
import Yexiong from './images/Yexiong.png';
// import Jianglin from './images/Jianglin.png';
import Longrong from './images/Longrong.png';
import Shiming from './images/Shiming.jpeg';
import Xiaolong from './images/xiaolong.jpg';
import Zhongyi from './images/Zhongyi.jpg';
import Runnan from './images/Runnan.jpeg';
import Xiaoqing from './images/Xiaoqing.jpg';
import Suqin from './images/Suqin.jpeg';
import Jiyang from './images/Jiyang.jpg';
import Xiuchuan from './images/Xiuchuan.png';
import Jun from './images/Jun.jpg';
import Peng from './images/Peng.png';
import Xiangyu from './images/Xiangyu.jpg';
import Keshen from './images/Keshen.jpg';
import Ziming from './images/Ziming.jpg';
import Tianyu from './images/Tianyu.jpg';
import Jiachen from './images/Jiachen.jpg';
import Longjie from './images/Longjie.jpg';
import Li from './images/Li.jpg';
import Ruojing from './images/Ruojing.jpg';
import Jiaxin from './images/Jiaxin.jpg';

// import ChenGong from './images/ChenGong.png';
// import GangNiu from './images/GangNiu.png';
// import MingmingGong from './images/MingmingGong.png';
// import bhan from './images/bhan.jpg';


class Team extends React.Component{

    scrollToAnchor = (anchorName) => {
	if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) {
		console.log("working..."+ anchorElement.offsetTop);
		document.body.scrollTop  = document.documentElement.scrollTop = anchorElement.offsetTop + 120;
	    }
	}
    }

    render() {
	return (
	    <div>
	      <HeadWhite />
	      <Banner title="RESEARCH TEAM"/>

	      <div className="container justify-content-md-center">
		<div className="row">
		  <div className="col-md-3 col-12 m-hidden" style={{paddingBottom:"50px"}}>
		    <button onClick={()=>this.scrollToAnchor('dir')} className="btn btn-outline-primary rounded-pill active"><Spacer amount={11}/>Director<Spacer amount={12}/></button> <br/> <br/>
                    {/* <button onClick={()=>this.scrollToAnchor('stra')} className="btn btn-outline-primary rounded-pill">Strategic collaborator</button> <br/> <br/> */}
                     <button onClick={()=>this.scrollToAnchor('con')} className="btn btn-outline-primary rounded-pill"><Spacer amount={6}/>Postdoctoral<Spacer amount={7}/></button> <br/> <br/>
		    <button onClick={()=>this.scrollToAnchor('phd')} className="btn btn-outline-primary rounded-pill"><Spacer amount={6}/>PhD Students<Spacer amount={6}/></button> <br/> <br/>
		    <button onClick={()=>this.scrollToAnchor('master')} className="btn btn-outline-primary rounded-pill"><Spacer amount={3}/>Master Students<Spacer amount={4}/></button> <br/> <br/>
		    <button onClick={()=>this.scrollToAnchor('honour')} className="btn btn-outline-primary rounded-pill"><Spacer amount={2}/>Honours Students<Spacer amount={2}/></button> <br/> <br/>
		    <button onClick={()=>this.scrollToAnchor('gra')} className="btn btn-outline-primary rounded-pill"><Spacer amount={12}/>Alumni<Spacer amount={13}/></button>
		  </div>

		  <div className="col-md-9 col-12 team-container">
		    <h3 id="dir">Director</h3> <br/>
		    <div className="row">
		      <PersonLink img={p1} name="Tongliang Liu" link="https://tongliang-liu.github.io/" description="(PI)"/>
		    </div>
		    <hr></hr><br/>

                    {/* <h3 id="stra">Strategic collaborator</h3> <br/> */}
                    {/* <div className="row"> */}
		    {/*   <Person img={GangNiu} name="Dr Gang Niu"  /> */}
                    {/*   <Person img={bhan} name="Dr Bo Han"  /> */}
                    {/*   <Person img={MingmingGong} name="Dr Mingming Gong"  /> */}
                    {/*   <Person img={ChenGong} name="Dr Chen Gong"  /> */}
		    {/* </div> */}
                    {/* <hr></hr><br/> */}

                    <h3 id="con">Research Associates/Postdoctoral Reseachers</h3> <br/>
                    <div className="row">
		      			<PersonLink img={yuyao} name="Yu Yao" link="https://a5507203.github.io/" description="(Lecturer in ML)"/>
						<PersonLink img={Runnan} name="Runnan Chen" link="https://scholar.google.com/citations?user=Uq2DuzkAAAAJ&hl=zh-CN" description="(Postdoc)"/>
                        <Person img={Xiaolong} name="Xiaolong Shi" description="(Postdoc)"/>
		            </div>
                    <hr></hr><br/>

		    <h3 id="phd">PhD Students</h3> <br/>
		    <div className="row">
					  <PersonLink img={xiaobo} name="Xiaobo Xia" link="https://xiaoboxia.github.io/"/>
		      		  <PersonLink img={chaojian} name="Chaojian Yu" link="https://chaojianyu.github.io/"/>
                      <PersonLink img={Vinoth} name="Vinoth Nandakumar" link="https://sites.google.com/view/vinothmn/"/>
					  <PersonLink img={Cong} name="Cong Lei" link="https://cong-lei.github.io/"/>
                      <PersonLink img={YuhaoWu} name="Yuhao Wu" link="https://www.linkedin.com/in/yuhao-wu-9a3753282/?originalSubdomain=au"/>
					  <PersonLink img={Huangzhuo} name="Zhuo Huang" link="https://zhuohuangai.github.io/"/>
					  <PersonLink img={Suqin} name="Suqin Yuan" link="https://suqinyuan.github.io/"/>
					  <PersonLink img={Jiyang} name="Jiyang Zheng" link="https://jiyang-zheng.github.io/"/>
                      <PersonLink img={Yexiong} name="Yexiong Lin" link="https://yexionglin.github.io/"/>
                      <PersonLink img={Zhaoqing} name="Zhaoqing Wang" link="https://derrickwang005.github.io/"/>
					  <PersonLink img={Muyang} name="Muyang Li" link="https://li-muyang.github.io/"/>
					  <PersonLink img={Runlin} name="Runlin Lin" link="https://runqilin.github.io/"/>
					  <PersonLink img={Xiuchuan} name="Xiuchuan Li" link="https://scholar.google.com/citations?hl=zh-CN&user=zGm9SJsAAAAJ"/>
					  <PersonLink img={Ziming} name="Ziming Hong" link="https://sites.google.com/view/ziminghong"/>
					  <PersonLink img={Jun} name="Jun Wang" link="https://ingramwang.github.io/"/>
					  <Person img={Tianyu} name="Tianyu Huang" />
					  <PersonLink img={Li} name="Li He" link="https://jerelyhe.github.io"/>
					  <PersonLink img={Peng} name="Peng Mi" link="https://mi-peng.github.io/"/>
					  <PersonLink img={Ruojing} name="Ruojing Dong" link="https://dongruojing.github.io/Academic-homepage/"/>
					  <PersonLink img={Jiaxin} name="Jiaxin Huang" link="https://scholar.google.com/citations?user=jfx4enUAAAAJ&hl=zh-CN"/>
					  <Person img={Xuan} name="Tong Xuan" description="(Part-time PhD student)"/>
                      <PersonLink img={dawei} name="Dawei Zhou" description="(Visiting PhD student)" link="https://dwdavidxd.github.io/"/>
                      <PersonLink img={Shikun} name="Shikun Li" description="(Visiting PhD student)" link="https://scholar.google.com/citations?user=FPzWYEsAAAAJ&hl=en"/>
		    </div>
		    <hr></hr><br/>
		    <h3 id="master">Master Students</h3> <br/>
		    <div className="row">
				      <Person img={Xiangyu} name="Xiangyu Sun"/>
					  <PersonLink img={Keshen} name="Keshen Zhou" link="https://www.linkedin.com/in/keshen-zhou-5b884821b/"/>
					  <Person img={Longjie} name="Longjie Zhao"/>

		    </div>
		    <hr></hr><br/>
		    <h3 id="honour">Honours Students</h3> <br/>
			<PersonLink img={Jiachen} name="Jiachen Lin" link="https://www.linkedin.com/in/jenny-lin-69bb06218/"/>

		    <div className="row">

		    </div>

		    <hr></hr><br/>
		    <h3 id="gra">Alumni</h3> <br/>
		    <div className="row">
			  <PersonLink img={Huaxi} name="Huaxi Huang (Postdoc)" link="https://coderhhx.github.io/" description="(Next position: Scientist at Lumachain)"/>
			  <PersonLink img={Zhongyi} name="Zhongyi Han (Postdoc)" link="https://zhyhan.github.io/" description="(Next position: Postdoc at KAUST)"/>
			  <PersonLink img={xinyu} name="Xiyu Yu (PhD)" link="https://scholar.google.com.au/citations?user=zDOKBjkAAAAJ&hl=en" description="(Next position: Baidu)"/>
		      <PersonLink img={erkun} name="Erkun Yang (PhD)" link="https://faculty.xidian.edu.cn/YangErkun/zh_CN/index/434064/list/index.htm" description="(Next position: Associate Professor at Xidian University)"/>
			  <PersonLink img={yuxuan} name="Yuxuan Du (PhD)" link="https://yuxuan-du.github.io/" description="(Next position: Senior Researcher at JD Explore Academy)"/>
		      <PersonLink img={xuefeng} name="Xuefeng Li (Visiting PhD)" link="https://www.cse.unsw.edu.au/~z5085453/" description="(Next position: Applied Scientist at Amazon)"/>
			  <PersonLink img={shijun} name="Shijun Cai (Visiting PhD)" link="https://www.linkedin.com/in/shijun-cai-7a472138/?originalSubdomain=au" description="(Next position: Postdoc at USYD)"/>
			  <PersonLink img={Yang} name="Shuo Yang (Visiting PhD)" link="https://http://shuoyang-1998.github.io/" description="(Next position: Associate Professor at HIT)" />
			  <PersonLink img={Xiaoqing} name="Xiaoqing Guo (Visiting PhD)" link="https://guo-xiaoqing.github.io/" description="(Next position: Postdoc at Oxford)" />
			  <PersonLink img={Joshua} name="Joshua Kim (Visiting PhD)" link="http://www.joshuakim.io/"/>
			  <PersonLink img={Longrong} name="Longrong Yang (Visiting PhD student)" link="https://longrongyang.github.io/"/>
              <PersonLink img={Shiming} name="Shiming Chen (Visiting PhD)" link="https://shiming-chen.github.io/"/>
			  <PersonLink img={yingbin} name="Yingbin Bai (PhD)" link="https://bybeye.github.io/" description="(Next position: Postdoc at ANU)"/> {/* id="12"  */}
			  <PersonLink img={sonhua} name="Songhua Wu (PhD)" link="https://scifancier.github.io/"/>
		      <PersonLink img={chencheng} name="Cheng Chen (MPhil)" link="https://scholar.google.com/citations?user=1zZ4zYIAAAAJ&hl=en" description="(Next position: PhD at UTS with full scholarships)"/>
              <PersonLink img={jialiang} name="Jialiang Shen (MPhil)" link="https://scholar.google.com/citations?hl=en&user=BJLDZPwAAAAJ" description="(Next position: SenseTime)"/>
		      <Person id="20" img={Kewei} name="Kewei Wang (MPhil)" description="(Next position: PhD at USTC with scholarships)"/>
              <PersonLink img={Pengqian} name="Pengqian Lu (MPhil)" link="https://pengqian-lu.github.io/" description="(Next position: PhD at UTS with full scholarships)"/>
			  <PersonLink img={zhengning} name="Zhengning Wu (MPhil)"  link="https://scholar.google.com/citations?hl=en&user=4C9_18oAAAAJ"/>
			  <Person img={Nguyen} name="Duc Nguyen (MPhil)" description="(Next Position: National Institute of Information and Communications Strategy)"/>
			  <PersonLink img={Kanghui} name="Hui Kang (MPhil)" link="https://randydl.github.io/"/>
		      <Person img={kangzhao} name="Kangzhao Xia (Honours)"/>
		      <Person img={Yuhao} name="Yuhao Hu (Honours)"/>
		      <Person img={jerry} name="Jerry Tan (Honours)"/>
              <Person img={James} name="James Wood (Honours)" description="(University Medal)"/>
		      <Person img={Jeremy} name="Jeremy Gillen (Honours)"/>
              <Person img={Jazlyn} name="Jazlyn Lin (Honours)"/>
              <Person img={Matthew} name="Matthew Raad (Honours)"/>
              <Person img={Yexin} name="Yexin Mao (Honours)"/>
              <Person img={Waugh} name="Finnegan Waugh (Honours)"/>
			  <Person img={Zhouyang} name="Yang Zhou (Honours)" description="(University Medal)"/>
              <Person img={Jiacheng} name="Jiacheng Zhang (Honours)" description="(University Medal)"/>
              <Person img={Junzhi} name="Junzhi Ning (Honours)" description="(University Medal)"/>
		    </div>

		    <br/> <br/>
		  </div>

		</div>
	      </div>

	    </div>
	);
    }
}

export default Team;
